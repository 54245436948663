import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {handleCopyToClipboard} from '../RC_Cherkasy/components/toolsFuncs';
import { Box, Typography, Container, Link, Paper, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';

const API_SERVER = 'https://api.rotary.org.ua';
//const API_SERVER = 'http://localhost:3001';
const CERNEL_URL = '/photos';
const CERNEL_URL_2 = '/photos';

function PhotosDisplay() {
    const [photos, setPhotos] = useState([]);
    const location = useLocation();
    const path = location.pathname.replace(CERNEL_URL+'/', '');
    const urlPath = path ? `/folders${CERNEL_URL_2}/${path}` : CERNEL_URL_2;

    // useEffect(() => {
    //     loadData();
    // }, []);

    useEffect(() => {
        loadData();
    }, [path]);

    const loadData = () => {
        console.log('loadData... url='+`${API_SERVER}${urlPath}`);
        // Формуємо правильний URL для запиту
        fetch(`${API_SERVER}${urlPath}`)
            .then(response => response.json())
            .then(urls => {
                console.log('loadData data='+JSON.stringify(urls));
                setPhotos(urls);
            })
            .catch(error => {
                console.error('Помилка при завантаженні фотографій:', error);
            });
    }

    return (
        <div>
            path={path}<br/>
            urlPath={urlPath}
            <h2>Галерея фотографій</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {photos.map((item, index) => (
                    <div key={index} style={{ margin: '10px', border: '1px solid gray' }}>
                        <img src={item} alt={`Фото ${index + 1}`} style={{ maxWidth: '200px', maxHeight: '200px' }} />
                        <IconButton onClick={() => handleCopyToClipboard(item)} aria-label="Facebook">
                        <MdEmail color="blue" size='1.5em' />
                        </IconButton>
                        url={item}
                    </div>
                ))}
                {photos && photos.length===0 && "папка пуста"}
            </div>
        </div>
    );
}

export default PhotosDisplay;
