
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { LOCALES } from '../../../i18n/locales';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Box, Typography, Container, Link } from '@mui/material';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {getRProjects} from '../R_ProjectsShowcase/getRProjects';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingFilters from '../fixcomponents/LandingFilters';
import LandingContentItems from '../../../components/LandingContentItems';
import LAccordionItem from '../../../components/LAccordionItem';
import LandingCards2List from '../../../components/LandingCards2List';
import LcButtonPhone from './LcButtonPhone';
import LcButtonEmail from './LcButtonEmail';
import LcButtonFB from './LcButtonFB';
import LcButtonYoutube from './LcButtonYoutube';
import LcButtonPdf from './LcButtonPdf';
import LandingProjectPage from './LandingProjectPage';
import ProjectPhotoCarusel from './ProjectPhotoCarusel';

import { fetchProject } from '../rotariansBD/api/api_projects';
import { fetchProjects } from '../rotariansBD/api/api_projects';
import { createProject} from '../rotariansBD/api/api_projects';

function limitString(inputString) {
    const maxChars = 100;
    const maxLines = 6;
  
    // Розділити рядок на масив рядків
    const lines = inputString.split('\n');
  
    let result = '';
  
    for (let i = 0; i < lines.length && i < maxLines; i++) {
      // Змінити поточний рядок, щоб він не перевищував максимальну кількість символів
      const currentLine = lines[i].substring(0, maxChars);
  
      // Додати поточний рядок до результату
      result += currentLine;
  
      // Якщо це не останній рядок і є ще рядки, додайте символ переносу рядка
      if (i < lines.length - 1 && i < maxLines - 1) {
        result += '\n';
      }
    }
  
    return result;
}
const onlydocss = (card) => {
    if (typeof card.docs === 'string') {
        // Розділення строки за комами і видалення пробільних символів
        return card.docs.split(',').map(img => img.trim());
    }
    return null;
};

function CardItemComponent({ card, index, tags, handleRowClick, currentLocale }) {
    const clubLogo = "https://res.cloudinary.com/tyko/image/upload/v1704543710/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_project_edlovp.png";
    const districtLogo = "https://res.cloudinary.com/tyko/image/upload/v1704614094/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/D2232_project_edlovp_o1oywq.png";

    const [user, setUser] = useState(card);

    useEffect(() => {
        console.log('=== fetchProject fetchUser id='+card.projectId);

        const fetchData = async (id) => {
            try {
                const fetchedUser = await fetchProject(id);
                console.log('=== fetchProject json='+JSON.stringify(fetchedUser[0]));
                setUser(fetchedUser[0]);
            } catch (error) {
                console.error(error);
            }
        };

        if (card && card.projectId) {
            console.log('=== fetchProject id='+card.projectId);
            fetchData(card.projectId);
        }
    }, []);


    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

return (
    <>
                <Card sx={{ maxWidth: 360, minWidth: 200, display: 'flex', flexDirection: 'column' }} key={index} 
                    //</>onClick={() => handleRowClick(index)}
                    >
                <CardActionArea>
                    <ProjectPhotoCarusel images={user.avatarimg.split(',')} />

                  <CardContent>
                    <Typography color='darkblue' gutterBottom variant="h5" component="div" onClick={() => handleRowClick(index)} fontWeight="bold" >
                    {user.title}
                    </Typography>

                    <Typography gutterBottom variant="h6" component="div">
                    {user.rtype && user.rtype==="District2232" ?
                    <>Дистрикт: Україна</> :
                    
                    <>
                    {currentLocale===LOCALES.UKRAINIAN ? 
                        <>Клуб: {user.rtype && user.rtype==="Rotaract" ? <>РАК</> :  <>РК</>} {user.rclub}</> 
                        :
                        <>Club: {user.rtype && user.rtype==="Rotaract" ? <>RAC</> :  <>RC</>} {user.rclub}</> 
                    }</>
                    
                    }
                    </Typography>

                    <Typography gutterBottom variant="h6" component="div">
                    Категорія: {user.category}
                    </Typography>

                    <Typography gutterBottom variant="h6" component="div">
                    Статус: {user.status}
                    </Typography>
                    
                    <Typography gutterBottom variant="h6" component="div">Опис:</Typography>
                    <Typography variant="body1" color="text.secondary">
                        {user.content && 
                            <lu>
                            <LandingContentItems content={user.content} />
                            </lu>
                        }
                        {user.description && 
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                            {limitString(user.description)}
                            </Typography>
                        }
                    </Typography>
                    {/* <br/>
                    <Button onClick={() => handleRowClick(index)} >Детальніше...</Button> */}
                    
                  </CardContent>
                </CardActionArea>
                <CardActions style={{ marginTop: 'auto' }}>
                    <Typography gutterBottom variant="h7" >
                    Primary contact: {user.primarycontact}
                    </Typography>

                    {user.email && <Tooltip title={'Email'}>
                    <IconButton onClick={() => copyToClipboard(user.email)} aria-label="Facebook">
                        <MdEmail color="blue" size='1.5em' />
                    </IconButton>
                    </Tooltip>}

                    {user.phone && <Tooltip title={'Phone'}>
                    <IconButton onClick={() => copyToClipboard(user.phone)} aria-label="Facebook">
                        <PhoneIcon color="primary" size='1.5em' />
                    </IconButton>
                    </Tooltip>
                    }
                    
                    {user.youtube && (
                        <LcButtonYoutube url={user.youtube} size='default' />
                    )}
                    {user.fb && (
                        <LcButtonFB url={user.fb} size='default' />
                    )}
                    
                </CardActions>
                </Card>
                </>
    );
}

const LandingProjects = ({landItem}) => {
    const tags = landItem.LandingProjects;
    
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [tagedData, setTagedData] = useState([]);
    const [selectedProj, setSelectedProj] = useState(-1);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const [cardView, setCardView] = useState(0);   

    const categories = [
        "PEOPLE & COMMUNITIES",
        "WASH", 
        "CHILDREN",
        "MEDICAL",
        "MENTAL HEALTH CARE",
        "REHABILITATION OF WOUNDED",
        "SPECIAL VEHICLES",
        "HUMANITARIAN DEMINING",
        "LOGISTICS",
        "OTHER Needs",
    ];
    const statuses = [
        "Offered, looking for global grant partners",
        "Offered, looking for other partners",
        "In process", 
        "Permanently active",
        "During the war in Ukraine"
    ];
    const clubtype = [
        "Rotary",
        "Rotaract"
    ];
    
    const [countCategories, setCountCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(categories);
    const [selectedStatuses, setSelectedStatuses] = useState(statuses);
    const [selectedRType, setSelectedRType] = useState(clubtype);

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const d = await fetchProjects();//getRProjects(locale);
        
        const filteredData = d.filter(item => item.tags === tags);
        setData( filteredData );

        const filteredData2 = filterData(filteredData)
        setTagedData(filteredData2);

        console.log("DanatePage get data list success. const="+d.length);
    }

    useEffect(() => {
        const filteredData2 = filterData(data)
        setTagedData(filteredData2);
        console.log("Change curent filter ("+filteredData2.length+") "+selectedCategories);
    }, [data,selectedCategories, selectedStatuses, selectedRType])

    const filterData = (data) => {
        const d1 = data.filter((item, index) => {
            //console.log("======== "+item.category+"/"+selectedCategories);
            //setCountCategories(countCategories[index]+1);
            console.log("======== "+countCategories);
            return selectedCategories.includes(item.category);
        });
        const d2 = d1.filter((item, index) => {
            return selectedStatuses.includes(item.status);
        });
        const d3 = d2.filter((item, index) => {
            return selectedRType.includes(item.rtype);
        });

        return d3;
    }

    const cardViewChanged = (v) => {
        setCardView(v);
    }

    const cbCategories = (cat) => {
        setSelectedCategories(cat);
    }

    const cbStatuses = (st) => {
        setSelectedStatuses(st);
    }

    const cbRType = (st) => {
        setSelectedRType(st);
    }

    const copyToClipboard = (text) => {
        
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };
      
    const showCards = (cards) => {
        return (
            <>
            
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            {cards && cards.map((card, index) => (
                <CardItemComponent card={card} index={index} tags={tags} handleRowClick={handleRowClick} currentLocale={currentLocale} />
            ))}
            </Box>
            </>
        )
    }

    const getFirstImg = (imgstr) => {
        // Перевірка на пустий рядок
        if (!imgstr || imgstr.trim() === '') {
            return null; // Або ви можете повернути пустий рядок, залежно від вашого випадку використання
        }
    
        // Розділити рядок на масив за допомогою коми
        const urls = imgstr.split(',');
    
        // Повернути перший елемент масиву
        return urls[0];
    }

    const showTable = (cards) => {
        return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize: '16px'}}>Title</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Photo / Category</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Club</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Link</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Content</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Contact</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cards.map((card, index) => (
            (
                <TableRow
                    key={index}
                >

                <TableCell 
                    onClick={() => handleRowClick(index)}
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                    style={getRowStyle(index)}
                    sx={{fontSize: '16px'}}
                >
                    {card.title}
                </TableCell>

                <TableCell 
                    onClick={() => handleRowClick(index)}
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                    style={getRowStyle(index)}
                    sx={{fontSize: '16px'}}
                >
                    <img src={getFirstImg(card.avatarimg)} alt="" style={{ maxWidth: '100px' }} />
                    "{card.category}"
                </TableCell>
                
                <TableCell sx={{fontSize: '16px'}}>
                    {card.rtype && card.rtype==="Rotaract" ? <>RAC</> :  <>RC</>} <br/>{card.rclub}
                    
                </TableCell>

                <TableCell>
                  {card.genlink && <Link href={card.genlink} target="_blank" rel="noopener noreferrer">
                    Go to project page
                  </Link>}
{/*                    {card.docs && card.docs.map((item) => (
                        <LcButtonPdf url={item} size='default' />
                    ))}*/}
                    {onlydocss(card) && 
                    <>Documents:
                        {onlydocss(card).map((item) => (
                        <LcButtonPdf url={item} size='default' />
                    ))}
                    </>
                    }
                  
                </TableCell>
                

                <TableCell>
                  
                    {card.content && limitString(card.content[0].LandingMessage)}
                    {card.description && limitString(card.description)}
                  
                </TableCell>

                <TableCell>
                    {card.primarycontact}
                    <br/>
                    {card.email && (
                        <LcButtonEmail url={card.email} size='default' />
                    )}
                    {card.phone && (
                        <LcButtonPhone url={card.phone} size='small'/>
                    )}
                    {card.youtube && (
                        <LcButtonYoutube url={card.youtube} size='default' />
                    )}
                    {card.fb && (
                        <LcButtonFB url={card.fb} size='default' />
                    )}
                </TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
  )
}


    const showList = (cards) => {
        return (
            <>
            <LandingCards2List cards={cards} />
            </>
        )
    }
    const handleRowClick = (index) => {
        setSelectedProj(index);
    };
    const handleBack = () => {
        setSelectedProj(-1);
    }
    const getRowStyle = (index) => {
        if (selectedProj === index) {
          return { backgroundColor: '#f5f5f5' };
        } else if (hoveredRowIndex === index) {
          return { backgroundColor: '#e0e0e077' }; // Світліший колір при наведенні
        }
        return {};
      };

      
      const empty_project = {
        avatarimg: '', 
        title: '', 
        description: '',
        email: '', 
        phone: '', 
        primarycontact: '', 
        status: '',
        category: '',
        rclub: '',
        rtype: '',
        tags: '',
        imgs: '',
        videos: '',
        genlink: '',
        genlink: '', 
        docs: '', 
        fb: '', 
        youtube: '',
    }
    const handleAddAll = async (projects) => {
        //const projects_ = projects.slice(0, 2);
        const projects_ = projects;
        try {
            // Ітеруємося через всі проекти
            for (const project of projects_) {
                // Викликаємо createProject для кожного проекту
                const project2 = empty_project;

                
                if (project.avatarimg)  project2.avatarimg = project.avatarimg;
                if (project.title) project2.title = project.title;
                if (project.email) project2.email  = project.email;
                if (project.phone) project2.phone = project.phone;
                if (project.primarycontact) project2.primarycontact = project.primarycontact;
                if (project.status) project2.status = project.status;
                if (project.category) project2.category = project.category;
                if (project.rclub) project2.rclub = project.rclub;
                if (project.rtype) project2.rtype = project.rtype;
                if (project.tags) project2.tags = project.tags;
                if (project.genlink) project2.genlink = project.genlink;
                if (project.fb) project2.fb = project.fb;
                if (project.youtube) project2.youtube = project.youtube;

                if (project.content && project.content.length > 0) {
                    project2.description = project.content[0].LandingMessage;
                }
                delete project.content;

                if (project.imgs && project.imgs.length>0 ) {
                    project2.imgs = project.imgs.join(',');
                }
                if (project.videos && project.videos.length>0 ) {
                    project2.videos = project.videos.join(',');
                }
                if (project.docs && project.docs.length>0 ) {
                    project2.docs = project.docs.join(',');
                }

                await createProject(project2);
            }
            // Оповіщення про успішне додавання всіх проектів
            console.log("All projects added successfully");
        } catch (error) {
            // Обробка помилок, якщо є
            console.error("Error adding projects:", error);
        }
    };

    return (
        <>
        {/*data && //додавання БД з json в mysql
            <Button variant="contained" color="primary" onClick={() => handleAddAll(data)}>
                Add All Projects ({data.length})
            </Button>
    */}
            
        {selectedProj>-1 ?
            <LandingProjectPage proj={tagedData[selectedProj]} handleBack={handleBack} />
            :
        <>
            <LAccordionItem title="Filters" >
            <LandingFilters 
                categories={categories}
                statuses={statuses}
                clubtype={clubtype}
                selectedCategories={selectedCategories} 
                cbCategories={cbCategories} 
                selectedStatuses={selectedStatuses}
                cbStatuses={cbStatuses}
                selectedRType={selectedRType}
                cbRType={cbRType}
                countCategories={countCategories}
            /> 
            </LAccordionItem>

            <Box display="flex" flexDirection="row" pt='8px'>
                <Box display="flex" flexDirection="row" whiteSpace="nowrap" pr='8px'>
                    <Box whiteSpace="nowrap" pr='8px'>Вигляд: </Box>
                    <Button variant='contained' onClick={() => cardViewChanged(0)}>картки</Button>
                    {/*<Button variant='contained' onClick={() => cardViewChanged(1)} >list</Button>*/}
                    <Button variant='contained' onClick={() => cardViewChanged(2)} >таблиця</Button>
                </Box>
                <Box width="100%">
                </Box>
            </Box>
            
            <Box display="flex" flexDirection="row" pt='8px'>
                {tagedData &&
                <Box display="flex" flexDirection="row" whiteSpace="nowrap" pr='8px'>
                    Кількість проектів: {tagedData.length}
                </Box>
                }

                {/* <Box width="100%">
                </Box>
                { !tags.includes("#showcase") &&
                <Box whiteSpace="nowrap">
                    <Link href="/project_showcase" target="_blank" rel="noopener noreferrer">
                        всі проєкти Дистрикту 2232
                    </Link>
                </Box>} */}
            </Box>

            {tagedData &&
                cardView === 1 ? (
                    showList(tagedData)
                ) : cardView === 2 ? (
                    showTable(tagedData)
                ) : (
                    cardView === 0 && showCards(tagedData)
                )
            }

            {//<LandingContent data={data} currentLocale={currentLocale} designType={designType} />
            }
        </>}
        </>
  );
}

export default LandingProjects;