import React, { useContext, useEffect } from "react";
import { IntlProvider } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { AppBar, Toolbar, Box, Button, Menu, MenuItem, IconButton, Drawer, useMediaQuery, useTheme, Link } from '@mui/material';

import { LOCALES } from '../../../i18n/locales';
import { messages } from '../../../i18n/massages';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import BetaMessage from '../../../components/BetaMessage';
import '../../../components/Main.css';
import TLandingNavbar from "./TLandingNavbar";
import MyTLandingNavbar from "./MyTLandingNavbar";

import TLandingFooter from './TLandingFooter';
import TLandingNavbarV2Page from './TLandingNavbarV2Page';

//import Footer from "./Footer";
const ga4 = 'G-5P2E208YMM';

const TykoLanding = ({navbar, mynavbar, navbarFunc2, children, noWarningMode, designType, footer, languages, userProfile, onLogined, isClubMember, pageDublicateMenu }) => {
  const { currentLocale, setCurrentLocale } = useContext(LocalContext);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (true || process.env.NODE_ENV === 'production') {
        window.gtag('config', ga4, {
        page_path: location.pathname,
        });
    }
  }, [location]);

    return (
       
        <IntlProvider messages={messages[currentLocale]}
          locale={currentLocale}
          defaultLocale={LOCALES.UKRAINIAN} onError={() => {}}>

          {navbar && <TLandingNavbar navbar={navbar} designType={designType} languages={languages} userProfile={userProfile} isClubMember={isClubMember} />}
          
          {mynavbar && userProfile && <MyTLandingNavbar navbar={mynavbar} designType={designType} languages={languages} userProfile={userProfile} onLogined={onLogined} isClubMember={isClubMember} />}

          <div style={{minHeight: 'calc(100vh - 194px)', paddingBottom: '24px'}}>
            
            {pageDublicateMenu && isMobile && <TLandingNavbarV2Page navbar={navbar} designType={designType} /> 
            }
            
          <BetaMessage noWarningMode={noWarningMode} />
            {children}
          </div>
          
          <TLandingFooter footer={footer} />
          
      
        </IntlProvider>

  );
}

export default TykoLanding;