
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PhoneIcon from '@mui/icons-material/Phone';
import {handleCopyToClipboard} from './toolsFuncs';

const LcButtonPhone = ({url, size}) => {

    return (
        <Tooltip title={'Phone'}>
        <IconButton onClick={() => handleCopyToClipboard(url)} aria-label="Phone">
            <PhoneIcon color="white" fontSize={size} />
        </IconButton>
        </Tooltip>
    );
}

export default LcButtonPhone;