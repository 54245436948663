import React, { useState, useEffect } from 'react';
import { Button, Input, TextField, Paper, Typography, Box } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

const PhotoUploaderPage = () => {
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [uploadPath, setUploadPath] = useState(localStorage.getItem('uploadPath') || 'uploads');
  const [uploadTitle, setUploadTitle] = useState('');
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    localStorage.setItem('uploadPath', uploadPath);
  }, [uploadPath]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedPhotos([...selectedPhotos, ...files]);
  };

  const handlePathChange = (e) => {
    setUploadPath(e.target.value);
  };

  const handleTitleChange = (e) => {
    setUploadTitle(e.target.value);
  };

  const handleUploadClick = () => {
    const formData = new FormData();

    formData.append('path', uploadPath);
    formData.append('title', uploadTitle);

    selectedPhotos.forEach((file, index) => {
      formData.append(`photos`, file);
    });

    const apiUrl = 'https://api.rotary.org.ua/upload';

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        setSelectedPhotos([]);
        setUploadTitle('');
      })
      .catch((error) => {
        console.error('Помилка завантаження: ', error);
      });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    setSelectedPhotos([...selectedPhotos, ...files]);
  };

    const goToPhotoPage = () => {
        const url = uploadPath.replace("uploads", "");
        const url2 = 'https://beta.rotary.org.ua/photos'+url;
        window.open(url2, '_blank');
        console.log('goToPhotoPage = '+url2)
    }

  return (
    <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Завантаження фотографій
      </Typography>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: `2px dashed ${isDragOver ? 'green' : 'lightgray'}`,
          borderRadius: '8px',
          padding: '16px',
          marginBottom: '16px',
        }}
      >
        <CloudUploadIcon fontSize="large" />
        <Typography variant="body1" gutterBottom>
          Перетягніть сюди фотографії або натисніть кнопку нижче, щоб вибрати файли.
        </Typography>
      </div>
      <input
        type="file"
        id="photo-input"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-input">
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Вибрати фотографії
        </Button>
      </label>
      <Box display="flex" flexDirection='row' gap={1} >
        <TextField
            label="Шлях для збереження"
            value={uploadPath}
            onChange={handlePathChange}
            fullWidth
            style={{ margin: '16px 0' }}
        />
        <Button onClick={goToPhotoPage}>Переглянути</Button>
      </Box>
      <TextField
        label="Заголовок"
        value={uploadTitle}
        onChange={handleTitleChange}
        fullWidth
        style={{ margin: '16px 0' }}
      />
      <Typography variant="body1" gutterBottom>
        {selectedPhotos.length} фото вибрано
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUploadClick}
        disabled={selectedPhotos.length === 0 || uploadTitle === ''}
      >
        Завантажити
      </Button>
    </Paper>
  );
};

export default PhotoUploaderPage;
