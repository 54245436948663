
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MdEmail } from 'react-icons/md';
import {handleCopyToClipboard} from './toolsFuncs';

const LcButtonEmail = ({url,size}) => {

    return (
        <Tooltip title={'Email'}>
        <IconButton onClick={() => handleCopyToClipboard(url)} aria-label="Email">
            <MdEmail color="white" fontSize={size} />
        </IconButton>
        </Tooltip>
    );
}

export default LcButtonEmail;