import React, {useState, useEffect} from 'react';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Box, Container, Grid, Typography } from '@mui/material'

import {getInteractClubs} from '../api/interact';
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import TableLineComponent from '../components/TableLineComponent';

const breadcrumbs = ["Дистрикт 2232", "Інтеракт Клуби"];

const tableTitles = {
    District: "Назва клубу",
    City: "Клуб спонсор",
    //Name: "Президент спонсор клубу",
    //Email: "Контактна пошта",
};

const InteractClubsPage = () => {
     
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const icc = await getInteractClubs();
          setData(icc);
          console.log("IccPage get ICC list success. const="+icc.length);
        } catch (error) {
          console.log("IccPage get ICC list error="+error);
        }
      };
      fetchData();
    }, [])

    return (
        <MainPage noWarningMode='test' breadcrumbs={breadcrumbs} >
        <LandingBlock mode='light'>
        <Container>
            <br/>
            <Typography variant="body1">Interact надає молоді віком 12-18 років можливість спілкуватися з іншими молодими людьми в їхній громаді чи школі, одночасно розвиваючи свої лідерські навички.</Typography>
            <br/>
            <Typography variant="h6">Інтераст Клуби</Typography>

            <TableLineComponent 
                data={data}
                tableTitles={tableTitles}
                addNumber
            />
        </Container>
        </LandingBlock>
        </MainPage>
  );
}

export default InteractClubsPage;