export function limitString(inputString, maxLines = 6, maxChars = 100) {
  
    // Розділити рядок на масив рядків
    const lines = inputString.split('\n');
  
    let result = '';
  
    for (let i = 0; i < lines.length && i < maxLines; i++) {
      // Змінити поточний рядок, щоб він не перевищував максимальну кількість символів
      const currentLine = lines[i].substring(0, maxChars);
  
      // Додати поточний рядок до результату
      result += currentLine;
  
      // Якщо це не останній рядок і є ще рядки, додайте символ переносу рядка
      if (i < lines.length - 1 && i < maxLines - 1) {
        result += '\n';
      }
    }
  
    return result;
  }