import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import LandingContentItems from './LandingContentItems';

const LandingMatrixPrinterPaper = ({ itemArr }) => {
    return (
        <Box 
            sx={{
                background: `url('https://admin.designsnprint.com/img/products/large/dnp-18962062-96968073.jpg') no-repeat center center`, // Встановити фонове зображення
                backgroundSize: 'cover', // Додатково, щоб зображення масштабувалося
                padding: '10px 12% 10px 12% ',  // Відступи
                maxWidth: 'calc(100vw - 100px)',
                overflow: 'hidden',
            }}
        >
            <Box sx={{ 
                overflowX: 'auto', 
                whiteSpace: 'nowrap', 
                }}>
            {itemArr && <LandingContentItems content={itemArr} />}
            </Box>
        </Box>
    );
  };

export default LandingMatrixPrinterPaper;
