import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, 
        Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { fetchProjects, createProject, updateProject, deleteProject } from '../api/api_projects';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const empty_project = {
    project_id: '', 
    avatarimg: '', 
    title: '', 
    description: '',
    email: '', 
    phone: '', 
    primarycontact: '', 
    status: '',
    category: '',
    rclub: '',
    rtype: '',
    tags: '',
    imgs: '',
    videos: '',
    genlink: '',
    genlink: '', 
    docs: '', 
    fb: '', 
    youtube: '',
}

const ProjectsCRUD = ({tags}) => {
    const [projects, setProjects] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentProject, setCurrentProject] = useState(empty_project);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedProjects = await fetchProjects();
                const filteredData = tags ? fetchedProjects.filter(item => item.tags === tags) : fetchedProjects;

                setProjects(filteredData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handleOpen = (project = empty_project) => {
        setCurrentProject(project);
        setIsEditMode(!!project.project_id);
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        console.log('=== handleSubmit...');
        try {
            if (isEditMode) {
                await updateProject(currentProject.project_id, currentProject);
            } else {
                await createProject(currentProject);
            }
            handleClose();
            const updatedProjects = await fetchProjects();
            setProjects(updatedProjects);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (projectId) => {
        try {
            await deleteProject(projectId);
            const updatedProjects = await fetchProjects();
            setProjects(updatedProjects);
        } catch (error) {
            console.error(error);
        }
    };

    const handleFileSelect = (event) => {
        // Логіка обробки вибору файлу, наприклад, збереження шляху файлу або самого файлу
        // Вам потрібно буде використовувати FileReader або інший спосіб для обробки вибраного файлу
    };

    const getFirstImg = (imgstr) => {
        // Перевірка на пустий рядок
        if (!imgstr || imgstr.trim() === '') {
            return null; // Або ви можете повернути пустий рядок, залежно від вашого випадку використання
        }
    
        // Розділити рядок на масив за допомогою коми
        const urls = imgstr.split(',');
    
        // Повернути перший елемент масиву
        return urls[0];
    }    

    return (
        <div>
            <Button variant="contained" color="primary" onClick={() => handleOpen()}>
                Add Project
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>id</TableCell>
                            <TableCell>avatarimg</TableCell>
                            <TableCell>title</TableCell>
                            <TableCell>email</TableCell>
                            <TableCell>phone</TableCell>
                            <TableCell>primarycontact</TableCell>
                            <TableCell>status</TableCell>
                            <TableCell>category</TableCell>
                            <TableCell>rclub</TableCell>
                            <TableCell>rtype</TableCell>
                            <TableCell>tags</TableCell>
                            <TableCell>description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map((project) => (
                            <TableRow key={project.project_id}>
                                <TableCell>{project.project_id}</TableCell>
                                <TableCell><img src={getFirstImg(project.avatarimg)} height='70px' /></TableCell>
                                <TableCell>{project.title}</TableCell>
                                <TableCell>{project.email}</TableCell>
                                <TableCell>{project.phone}</TableCell>
                                <TableCell>{project.primarycontact}</TableCell>
                                <TableCell>{project.status}</TableCell>
                                <TableCell>{project.category}</TableCell>
                                <TableCell>{project.rclub}</TableCell>
                                <TableCell>{project.rtype}</TableCell>
                                <TableCell>{project.tags}</TableCell>
                                <TableCell>
                                    <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                                        {project.description}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleOpen(project)}>Edit</Button>
                                    <Button onClick={() => handleDelete(project.project_id)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEditMode ? 'Edit Project' : 'Add Project'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isEditMode ? 'Update the project details.' : 'Enter the details of the new project.'}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="title"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={currentProject.title}
                        onChange={(e) => setCurrentProject({ ...currentProject, title: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="primarycontact"
                        label="primarycontact"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={currentProject.primarycontact}
                        onChange={(e) => setCurrentProject({ ...currentProject, primarycontact: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={currentProject.email}
                        onChange={(e) => setCurrentProject({ ...currentProject, email: e.target.value })}
                    />
                    
                    <TextField
                        margin="dense"
                        id="phone"
                        label="Phone"
                        type="phone"
                        fullWidth
                        variant="standard"
                        value={currentProject.phone}
                        onChange={(e) => setCurrentProject({ ...currentProject, phone: e.target.value })}
                    />
                    <Box display="flex" alignItems="center" gap={2}>
                        <TextField
                            margin="dense"
                            id="avatarimg"
                            label="Avatar"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={currentProject.avatarimg}
                            onChange={(e) => setCurrentProject({ ...currentProject, avatarimg: e.target.value })}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="upload picture"
                                    component="label"
                                >
                                    <input
                                    hidden
                                    accept="image/*"
                                    type="file"
                                    onChange={handleFileSelect}
                                    />
                                    <PhotoCamera />
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        {currentProject.avatarimg && (
                            <img
                                src={currentProject.avatarimg}
                                alt="Avatar preview"
                                width='auto'
                                height='56px'
                            />
                        )}
                    </Box>
                    <TextField
                        margin="dense"
                        id="category"
                        label="category"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={currentProject.category}
                        onChange={(e) => setCurrentProject({ ...currentProject, category: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="rclub"
                        label="rclub"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={currentProject.rclub}
                        onChange={(e) => setCurrentProject({ ...currentProject, rclub: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="rtype"
                        label="rtype"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={currentProject.rtype}
                        onChange={(e) => setCurrentProject({ ...currentProject, rtype: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="tags"
                        label="tags"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={currentProject.tags}
                        onChange={(e) => setCurrentProject({ ...currentProject, tags: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="genlink"
                        label="genlink"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={currentProject.genlink}
                        onChange={(e) => setCurrentProject({ ...currentProject, genlink: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="description"
                        type="text"
                        multiline
                        minRows={3}
                        //maxRows={6}
                        fullWidth
                        variant="standard"
                        value={currentProject.description}
                        onChange={(e) => setCurrentProject({ ...currentProject, description: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>{isEditMode ? 'Update' : 'Add'}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ProjectsCRUD;
