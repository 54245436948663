import React, { useEffect, useState } from "react";

import MainPage from "../components/Main";
import EventsFrame from "./EventsFrame";

const EventsPage = () => {
    return (
        <>
          <MainPage noWarningMode='test'>
            <EventsFrame />
          </MainPage>
        </>
  );
}

export default EventsPage;