
import { LOCALES } from '../../../i18n/locales';

export const getRCLanding2 = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const cherkasyData_ukr = [
    {
        id: 0,
        header: 'Огляд проектів',
        content: [
            {LandingMessage: `Переглядайте або шукайте проекти за допомогою одного або кількох фільтрів у списку.`},
        ],
    },
    {
        id: 1,
        LandingProjects2: `#singapore`,
    }
];


const cherkasyData_eng = [
    {
        id: 0,
        header: 'Overview of projects',
        content: [
            {LandingMessage: `Browse or search for projects using one or more filters in the list.`},
        ],
    },
    {
        id: 1,
        LandingProjects2: `#singapore`,
    }
];