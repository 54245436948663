
import { LOCALES } from '../../../i18n/locales';

export const getRCCherkasyRotaract = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};
            
const cherkasyData_ukr = [
    {
        id: 0,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267645854-509092424.jpg",
        header: 'Ротаракт Клуби:',
        content: [
            {LandingMessage: `Ротаракт Клуб Черкаси`},
            {//LandingVideo: `Що таке Ротарі?`, link: 'https://drive.google.com/open?id=1OtJVaN1aciz-nQ-tttKLkFxM7joIKwdf'
            },
            
            {LandingLink: "Відео", link: "https://drive.google.com/open?id=1OtJVaN1aciz-nQ-tttKLkFxM7joIKwdf"},
        ],
    },
    {
        id: 0,
        img: "",
        header: 'Satellite Clubs:',
        content: [
            {LandingMessage: `Rotary club Cherkasy satellite club Cherkasy-Sky (2020-2022)`},
            
            {LandingLink: "Facebook", link: "https://www.facebook.com/yaslafest"},
        ],
    },
];

const cherkasyData_eng = [
    {
        id: 0,
        img: "",
        header: 'Rotaract Clubs:":',
        content: [
            {LandingMessage: `Rotaract Club "Cherkasy`},
            {LandingLink: "Відео", link: "https://drive.google.com/open?id=1OtJVaN1aciz-nQ-tttKLkFxM7joIKwdf"},
        ],
    },
    {
        id: 0,
        img: "",
        header: 'Satellite Clubs:":',
        content: [
            {LandingMessage: `Rotary club Cherkasy satellite club Cherkasy-Sky (2020-2022)`},
            
            {LandingLink: "Facebook", link: "https://www.facebook.com/yaslafest"},
        ],
    },
];