
import React, { useEffect, useState, useContext, useRef  } from "react";

import { Box, Typography, Container, Link, Paper, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import LandingContentItems from '../../../components/LandingContentItems';
import LcButtonPdf from './LcButtonPdf';
import {handleCopyToClipboard} from './toolsFuncs';
import PhotoCarusel from '../../../modules/PhotoCarusel';
import ProjectPhotoCarusel from './ProjectPhotoCarusel';


const LandingProjectPage = ({proj, handleBack}) => {
    const tags = "#showcase";
    const clubLogo = "https://res.cloudinary.com/tyko/image/upload/v1704543710/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/RC_Cherkasy_project_edlovp.png";
    const districtLogo = "https://res.cloudinary.com/tyko/image/upload/v1704614094/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/D2232_project_edlovp_o1oywq.png";
    
    const index = 0;
    const onlyimgs = () => {
        if (typeof proj.avatarimg === 'string') {
            // Розділення строки за комами і видалення пробільних символів
            const imagesArray = proj.avatarimg.split(',').map(img => img.trim());
    
            // Фільтрація масиву, щоб залишити тільки зображення з певними розширеннями
            return imagesArray;//.filter((img) => /\.(jpg|jpeg|png)$/i.test(img));
        }
        return null;
    };
    const onlydocss = () => {
        if (typeof proj.docs === 'string') {
            // Розділення строки за комами і видалення пробільних символів
            return proj.docs.split(',').map(img => img.trim());
        }
        return null;
    };
    

    const showProject = (proj) => {
        return (
            <>
            
            <Box display="flex" flexDirection='column'  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            

                <Paper sx={{p: '16px'}}>
                    
                    <Button variant='contained' onClick={handleBack}>back</Button>

                    <ProjectPhotoCarusel images={onlyimgs()} />
                    {//JSON.stringify(proj.imgs)
                    /* {onlyimgs() && 
                        <div style={{ maxHeight_: '100px' }} >
                        <PhotoCarusel images={onlyimgs()} />
                        </div>
                    } */}

                    {onlydocss() && 
                    <Typography gutterBottom variant="h6" component="div">
                        Documents:
                        {onlydocss().map((item) => (
                        <LcButtonPdf url={item} size='default' />
                    ))}
                    </Typography>
                    }
                  
                  
                    <Typography color='darkblue' gutterBottom variant="h4" component="div">
                    {proj.title}
                    </Typography>

                    <Typography gutterBottom variant="h6" component="div">
                    Club: {proj.rtype && <>{proj.rtype} Club</>} {proj.rclub}
                    </Typography>

                    <Typography gutterBottom variant="h6" component="div">
                    Category: {proj.category}
                    </Typography>

                    <Typography gutterBottom variant="h6" component="div">
                    Status: {proj.status}
                    </Typography>


                    {proj.genlink && <Link href={proj.genlink} target="_blank" rel="noopener noreferrer">
                        go to project page
                    </Link>}

                    <Typography variant="subtitle2" color="text.secondary">
                    {proj.description && proj.description.split('\n').map((line, index, array) => {
                        const trimmedLine = line.trim();
                        const isBr = index > 0 && array[index - 1].trim() === '' && trimmedLine !== '';
                        const isBold = index > 0 && array[index - 1].trim() === '' && trimmedLine !== '' && array[index - 2].trim() === '';
                        const isLink = line.includes('http');
                        const isImg = line.trim().endsWith('.jpg') || line.trim().endsWith('.png');
                        return (
                        <Typography variant="h5"
                            key={index}
                            style={{ whiteSpace: 'pre-line', fontWeight: isBold ? 'bold' : 'normal' }}
                        >
                            {isBr && <br />}
                            {isImg ? (
                            <img src={line} alt="image" style={{ width: '30%' }} />
                            ) : isLink ? (
                                <a href={line} target="_blank" rel="noopener noreferrer">
                                    {line}
                                </a>
                            ) : (
                            line
                            )}
                        </Typography>
                        );
                    })}
                    </Typography>


                    
                
                    <Typography gutterBottom variant="h7" >
                    Primary contact: {proj.primarycontact}
                    </Typography>

                    {proj.email && <Tooltip title={'Пошта'}>
                    <IconButton onClick={() => handleCopyToClipboard(proj.email)} aria-label="Facebook">
                        <MdEmail color="blue" size='1.5em' />
                    </IconButton>
                    </Tooltip>}

                    {proj.phone && <Tooltip title={'Телефон'}>
                    <IconButton onClick={() => handleCopyToClipboard(proj.phone)} aria-label="Facebook">
                        <PhoneIcon color="primary" size='1.5em' />
                    </IconButton>
                    </Tooltip>
                    }
                    
                
                </Paper>
            
            </Box>
            </>
        )
    }



    

    return (
        <>
            {showProject(proj)}
            
        </>
  );
}

export default LandingProjectPage;