
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { LOCALES } from '../../../i18n/locales';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Box, Typography, Container, Link } from '@mui/material';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import { Grid } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';

import {getRProjects} from '../R_ProjectsShowcase/getRProjects';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingFilters from '../fixcomponents/LandingFilters';
import LandingContentItems from '../../../components/LandingContentItems';
import LAccordionItem from '../../../components/LAccordionItem';
import LandingCards2List from '../../../components/LandingCards2List';
import LcButtonPhone from './LcButtonPhone';
import LcButtonEmail from './LcButtonEmail';
import LcButtonFB from './LcButtonFB';
import LcButtonYoutube from './LcButtonYoutube';
import LcButtonPdf from './LcButtonPdf';
import LandingProjectPage from './LandingProjectPage';
import ProjectPhotoCarusel from './ProjectPhotoCarusel';

import { fetchProject } from '../rotariansBD/api/api_projects';
import { fetchProjects } from '../rotariansBD/api/api_projects';
import { createProject} from '../rotariansBD/api/api_projects';

ChartJS.register(ArcElement, ChartTooltip, Legend);

function limitString(inputString) {
    const maxChars = 100;
    const maxLines = 6;
  
    // Розділити рядок на масив рядків
    const lines = inputString.split('\n');
  
    let result = '';
  
    for (let i = 0; i < lines.length && i < maxLines; i++) {
      // Змінити поточний рядок, щоб він не перевищував максимальну кількість символів
      const currentLine = lines[i].substring(0, maxChars);
  
      // Додати поточний рядок до результату
      result += currentLine;
  
      // Якщо це не останній рядок і є ще рядки, додайте символ переносу рядка
      if (i < lines.length - 1 && i < maxLines - 1) {
        result += '\n';
      }
    }
  
    return result;
}
const onlydocss = (card) => {
    if (typeof card.docs === 'string') {
        // Розділення строки за комами і видалення пробільних символів
        return card.docs.split(',').map(img => img.trim());
    }
    return null;
};

const LandingChartProjects = ({landItem}) => {
    const tags = landItem.LandingChartProjects;
    
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const [tagedData, setTagedData] = useState([]);
    const [selectedProj, setSelectedProj] = useState(-1);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const [cardView, setCardView] = useState(0);   

    const categories = [
        "PEOPLE & COMMUNITIES",
        "WASH", 
        "CHILDREN",
        "MEDICAL",
        "MENTAL HEALTH CARE",
        "REHABILITATION OF WOUNDED",
        "SPECIAL VEHICLES",
        "HUMANITARIAN DEMINING",
        "LOGISTICS",
        "OTHER Needs",
    ];
    const statuses = [
        "Offered, looking for global grant partners",
        "Offered, looking for other partners",
        "In process", 
        "Permanently active",
        "During the war in Ukraine"
    ];
    const clubtype = [
        "Rotary",
        "Rotaract"
    ];
    
    const [countCategories, setCountCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(categories);
    const [selectedStatuses, setSelectedStatuses] = useState(statuses);
    const [selectedRType, setSelectedRType] = useState(clubtype);

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const d = await fetchProjects();//getRProjects(locale);
        
        const filteredData = d.filter(item => item.tags === tags);
        setData( filteredData );

        const filteredData2 = filterData(filteredData)
        setTagedData(filteredData2);

        console.log("DanatePage get data list success. const="+d.length);
    }

    useEffect(() => {
        const filteredData2 = filterData(data)
        setTagedData(filteredData2);
        console.log("Change curent filter ("+filteredData2.length+") "+selectedCategories);
    }, [data,selectedCategories, selectedStatuses, selectedRType])

    const filterData = (data) => {
        return data;
    }

    const getFirstImg = (imgstr) => {
        // Перевірка на пустий рядок
        if (!imgstr || imgstr.trim() === '') {
            return null; // Або ви можете повернути пустий рядок, залежно від вашого випадку використання
        }
    
        // Розділити рядок на масив за допомогою коми
        const urls = imgstr.split(',');
    
        // Повернути перший елемент масиву
        return urls[0];
    }

    const showTable = (cards) => {
        return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontSize: '16px'}}>Title</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Photo / Category</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Club</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Link</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Content</TableCell>
            <TableCell sx={{fontSize: '16px'}}>Contact</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cards.map((card, index) => (
            (
                <TableRow
                    key={index}
                >

                <TableCell 
                    onClick={() => handleRowClick(index)}
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                    style={getRowStyle(index)}
                    sx={{fontSize: '16px'}}
                >
                    {card.title}
                </TableCell>

                <TableCell 
                    onClick={() => handleRowClick(index)}
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                    style={getRowStyle(index)}
                    sx={{fontSize: '16px'}}
                >
                    <img src={getFirstImg(card.avatarimg)} alt="" style={{ maxWidth: '100px' }} />
                    "{card.category}"
                </TableCell>
                
                <TableCell sx={{fontSize: '16px'}}>
                    {card.rtype && card.rtype==="Rotaract" ? <>RAC</> :  <>RC</>} <br/>{card.rclub}
                    
                </TableCell>

                <TableCell>
                  {card.genlink && <Link href={card.genlink} target="_blank" rel="noopener noreferrer">
                    Go to project page
                  </Link>}
{/*                    {card.docs && card.docs.map((item) => (
                        <LcButtonPdf url={item} size='default' />
                    ))}*/}
                    {onlydocss(card) && 
                    <>Documents:
                        {onlydocss(card).map((item) => (
                        <LcButtonPdf url={item} size='default' />
                    ))}
                    </>
                    }
                  
                </TableCell>
                

                <TableCell>
                  
                    {card.content && limitString(card.content[0].LandingMessage)}
                    {card.description && limitString(card.description)}
                  
                </TableCell>

                <TableCell>
                    {card.primarycontact}
                    <br/>
                    {card.email && (
                        <LcButtonEmail url={card.email} size='default' />
                    )}
                    {card.phone && (
                        <LcButtonPhone url={card.phone} size='small'/>
                    )}
                    {card.youtube && (
                        <LcButtonYoutube url={card.youtube} size='default' />
                    )}
                    {card.fb && (
                        <LcButtonFB url={card.fb} size='default' />
                    )}
                </TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
  )
}


    const showList = (cards) => {
        return (
            <>
            <LandingCards2List cards={cards} />
            </>
        )
    }
    const handleRowClick = (index) => {
        setSelectedProj(index);
    };
    const handleBack = () => {
        setSelectedProj(-1);
    }
    const getRowStyle = (index) => {
        if (selectedProj === index) {
          return { backgroundColor: '#f5f5f5' };
        } else if (hoveredRowIndex === index) {
          return { backgroundColor: '#e0e0e077' }; // Світліший колір при наведенні
        }
        return {};
      };

      
      const empty_project = {
        avatarimg: '', 
        title: '', 
        description: '',
        email: '', 
        phone: '', 
        primarycontact: '', 
        status: '',
        category: '',
        rclub: '',
        rtype: '',
        tags: '',
        imgs: '',
        videos: '',
        genlink: '',
        genlink: '', 
        docs: '', 
        fb: '', 
        youtube: '',
    }


    // Порахунок кількості кожного типу в масиві
    const counts = tagedData.reduce((acc, curr) => {
        acc[curr.category] = (acc[curr.category] || 0) + 1;
        return acc;
    }, {});

    const counts2 = tagedData.reduce((acc, curr) => {
        acc[curr.category2] = (acc[curr.category2] || 0) + 1;
        return acc;
    }, {});

    const counts3 = tagedData.reduce((acc, curr) => {
        acc[curr.status] = (acc[curr.status] || 0) + 1;
        return acc;
    }, {});

    const counts4 = tagedData.reduce((acc, curr) => {
        acc[curr.budget] = (acc[curr.budget] || 0) + 1;
        return acc;
    }, {});

    // Підготовка даних для кругової діаграми
    const dataChart = (param) => {
        return {
            labels: Object.keys(param),
            datasets: [{
                data: Object.values(param),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    // Додайте інші кольори за потреби
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    // Додайте інші кольори за потреби
                ],
                borderWidth: 1,
            }],
        }
    };

    return (
        <>
        { //JSON.stringify(counts)
        }
        
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
            <div style={{ maxWidth: '340px' }}>
                Project Type:
            <Pie data={dataChart(counts)} />
            </div>
            </Grid>

            <Grid item xs={12} md={6}>
            <div style={{ maxWidth: '340px' }}>
                Club Type:
            <Pie data={dataChart(counts2)} />
            </div>
            </Grid>

            <Grid item xs={12} md={6}>
            <div style={{ maxWidth: '340px' }}>
                Club Type:
            <Pie data={dataChart(counts3)} />
            </div>
            </Grid>

            <Grid item xs={12} md={6}>
            <div style={{ maxWidth: '340px' }}>
                Budget:
            <Pie data={dataChart(counts4)} />
            </div>
            </Grid>
        </Grid>
        </>
  );
}

export default LandingChartProjects;