import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button } from '@mui/material'
import './Pages.css';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import { useLocation } from 'react-router-dom';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import RotaractClubsPage from './RotaractClubsPage';
import RotaryClubsPage from './RotaryClubsPage';
import InteractClubsPage from './InteractClubsPage';

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const TabPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    let activeTab1;
    if (type === 'rotary') {
        activeTab1 = 0;
    } else if (type === 'rotaract') {
        activeTab1 = 1;
    } else if (type === 'satelite') {
        activeTab1 = 2;
    } else if (type === 'interact') {
        activeTab1 = 3;
    } else {
    // If the 'type' parameter is none of the specified values,
    // you may set a default value or handle it according to your requirement.
    // For example, setting a default value to 0:
    activeTab1 = 0;
    }

  const [activeTab, setActiveTab] = useState(activeTab1);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Ротарі Клуби" />
        <Tab label="Ротаракт Клуби" />
        <Tab label="Сателітні Клуби" />
        <Tab label="Інтеракт Клуби" />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <RotaryClubsPage />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <RotaractClubsPage />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <InteractClubsPage />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <InteractClubsPage />
      </TabPanel>
    </div>
  );
};

const DistrictClubsPage = () => {

  return (
    <MainPage>
      <LandingBlock mode='light'>
        <Box className="section text-left" >
            <TabPage />
        </Box>
      </LandingBlock>
    </MainPage>
  )
};

export default DistrictClubsPage;
