import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, Menu, MenuItem, IconButton, Drawer, useMediaQuery, useTheme, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoginIcon from '@mui/icons-material/Login';

import './TLandingNavbar.css';
import { LocalContext } from '../../../tools/LanguageContextProvider';
import MenuIcon from '@mui/icons-material/Menu';
import { LOCALES } from '../../../i18n/locales';
import D2232LOGO from '../../../assets/img/D2232.png';
import { styled } from '@mui/material/styles';

import Tooltip from '@mui/material/Tooltip';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { IoLogoYoutube } from 'react-icons/io5';
import { FaGlobe } from 'react-icons/fa';
import EmailIcon from '@mui/icons-material/Email';

const SquareIconButton = styled(IconButton)({
  borderRadius: '4px', // задаємо радіус куточків
  padding: '2px',
  // Додайте додаткові стилі, якщо потрібно
});

const TLandingNavbar = ({ navbar, languages, userProfile }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [scrollPosition, setScrollPosition] = useState(0);
    const { currentLocale, setCurrentLocale } = useContext(LocalContext);
    const navigate = useNavigate();

    // Стан для контролю підменю
    const [anchorEl, setAnchorEl] = useState({});

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        setCurrentLocale(languages && languages[0]==='Eng' ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLanguageClick = () => {
        setCurrentLocale(currentLocale === LOCALES.UKRAINIAN ? LOCALES.ENGLISH : LOCALES.UKRAINIAN);
    };

    const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMenuItemClick = (path) => {
        navigate(path);
    };

    const handleMenuOpen = (event, menuId) => {
        setAnchorEl({ ...anchorEl, [menuId]: event.currentTarget });
    };

    const handleMenuClose = (menuId) => {
        setAnchorEl({ ...anchorEl, [menuId]: null });
    };

    const renderMenuItem = (item, menuId, is_mobile, needBorder) => (
        <div key={item.title} className={is_mobile?"menu-button-ismobile": needBorder ? "menu-button-border" : "menu-button"}>
            <Button color="inherit" onClick={item.arr ? (e) => handleMenuOpen(e, menuId) : () => handleMenuItemClick(item.lref)}>
                {item.title}
                {item.arr && (
                    <ArrowDropDownIcon style={{ marginRight: '-10px' }} />
                )}
            </Button>

          {item.arr && (
            <Menu
              anchorEl={anchorEl[menuId]}
              keepMounted
              open={Boolean(anchorEl[menuId])}
              onClose={() => handleMenuClose(menuId)}
            >
              {item.arr.map((subItem, index) => renderMenuItem(subItem, `${menuId}-${index}`, false))}
            </Menu>
          )}
        </div>
      );

    const renderMenuItems = (menu, is_mobile) => (
        <>
            {menu.map((item, index) => renderMenuItem(item, `menu-${index}`, is_mobile, true))}
        </>
    );

    const navBarStyle = {
        transition: '0.1s',
        _height: scrollPosition > 0 ? '60px' : '110px',
        // backgroundColor і color видалені, оскільки вони тепер в CSS
    };
    
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

    return (
        <AppBar position="sticky" className="app-bar" sx={navBarStyle}>
            <Toolbar>
            {//navbar.menu={navbar && navbar.menu && JSON.stringify(navbar.menu)}
            }
            {!isMobile && navbar ?
                <Box className="navbar-container">
                    <Box className="navbar-logo">
                        {//navbar.logoUrl && <img src={navbar.logoUrl} style={{padding: "8px", marginLeft: "0px", maxHeight: isMobile?"44px":"130px" }}  alt="Логотип" />
                        navbar.logoUrl && <img 
                            src={navbar.logoUrl} 
                            style={navbar.addlogoborder ? { padding: "8px", marginLeft: "0px" } : { padding: "0px", marginLeft: "0px" }}  
                            alt="Логотип" 
                        />
                    
                        }
                    </Box>
                    <Box className="text-container">
                        <Box><span className="title"><FormattedMessage id={navbar.title} /></span></Box>
                        {navbar.menu && navbar.menu.length>1 &&
                            <Box className="menu-buttons-container" >
                                {navbar.menu && renderMenuItems(navbar.menu, false)}
                            </Box>
                        }
                    </Box>
                    <Box>
                    <Box display="flex" flexDirection='row' gap={1} >
                        
                            
                    
                        {navbar.socialsocialNetworks && navbar.socialsocialNetworks.fb &&
                        <Tooltip title={'Facebook'}>
                        <IconButton onClick={() => window.open(navbar.socialsocialNetworks.fb, '_blank')} aria-label="Facebook">
                            <FaFacebookF color="blue" size='1.0em' />
                        </IconButton>
                        </Tooltip>}
                        {navbar.socialsocialNetworks && navbar.socialsocialNetworks.insta &&
                        <Tooltip title={'Instagram'}>
                        <IconButton onClick={() => window.open(navbar.socialsocialNetworks.insta, '_blank')} aria-label="Instagram">
                            <FaInstagram color="blue" size='1.0em' />
                        </IconButton>
                        </Tooltip>
                        }
                        {navbar.socialsocialNetworks && navbar.socialsocialNetworks.youtube &&
                        <Tooltip title={'Ютуб канал'}>
                        <IconButton onClick={() => window.open(navbar.socialsocialNetworks.youtube, '_blank')} aria-label="YouTube">
                            <IoLogoYoutube color="blue" size='1.0em' />
                        </IconButton>
                        </Tooltip>
                        }
                        {navbar.socialsocialNetworks && navbar.socialsocialNetworks.email &&
                            <Tooltip title={'Email'}>
                            <IconButton aria-label="email" onClick={() => copyToClipboard(navbar.socialsocialNetworks.email)}>
                            <EmailIcon style={{ color: 'blue' }} />
                            </IconButton>
                            </Tooltip>
                        }
                        {!navbar.withoutd2232url &&
                        <Tooltip title={'rotary.org.ua'}>
                        <IconButton 
                            aria-label="website" 
                            onClick={() => window.open('https://rotary.org.ua', '_blank')}
                            style={{ color: 'blue' }} // Стиль кнопки
                        >
                            <FaGlobe />
                        </IconButton>
                        </Tooltip>}
                        
                    <Box className="language-button">
                        {/* <Link href="https://www.rotary.org" target="_blank" rel="noopener noreferrer">
                            rotary.org
                        </Link>
                        <Link href="https://rotary.org.ua" target="_blank" rel="noopener noreferrer">
                            rotary.org.ua
                        </Link> */}
                        { languages &&
                        <Button size="small" color="inherit" onClick={handleLanguageClick}>{currentLocale === LOCALES.UKRAINIAN ? 'Eng' : 'Укр'}</Button>
                        }
                    </Box>
                        {!userProfile && navbar && navbar.autorization && 
                        <IconButton href="/rc_cherkasy/myhome" aria-label="login">
                            <LoginIcon />
                        </IconButton>
                        }               
                    </Box>
                    </Box>
                </Box>

                : //mobile
                <Box className="navbar-container">
                    <Box className="navbar-logo">
                        {//navbar.logoUrl && <img src={navbar.logoUrl} style={{paddingTop: "16px", marginLeft: "0px", maxHeight: "44px" }}  alt="Логотип" />
                        //
                        navbar.logoUrl_mobile ? <img 
                                src={navbar.logoUrl_mobile} 
                                style={navbar.addlogoborder ? { padding: "8px", marginLeft: "0px" } : { padding: "0px", marginLeft: "0px" }} 
                                alt="Логотип" />
                            : navbar.logoUrl && <img 
                                src={navbar.logoUrl} 
                                style={navbar.addlogoborder ? { padding: "8px", marginLeft: "0px" } : { padding: "0px", marginLeft: "0px" }} 
                                alt="Логотип" />
                        }
                    </Box>
                    {/* <Box className="text-container">
                        <Box><span className="title"><FormattedMessage id={navbar.title} /></span></Box>
                        
                          
                    </Box> */}
                    <Box className="navbar-expander" flexGrow={1} />
                    <Box>
                    <Box display="flex" flexDirection='row' gap={1} >
                        <Box className="language-button" >

                            <Box display="flex" flexDirection='row' gap={0} >
                            {navbar.socialsocialNetworks && navbar.socialsocialNetworks.fb &&
                            <Tooltip title={'Facebook'}>
                            <IconButton onClick={() => window.open(navbar.socialsocialNetworks.fb, '_blank')} aria-label="Facebook">
                                <FaFacebookF color="blue" size='1.0em' />
                            </IconButton>
                            </Tooltip>}
                            {navbar.socialsocialNetworks && navbar.socialsocialNetworks.insta &&
                            <Tooltip title={'Instagram'}>
                            <IconButton onClick={() => window.open(navbar.socialsocialNetworks.insta, '_blank')} aria-label="Instagram">
                                <FaInstagram color="blue" size='1.0em' />
                            </IconButton>
                            </Tooltip>
                            }
                            {navbar.socialsocialNetworks && navbar.socialsocialNetworks.youtube &&
                            <Tooltip title={'Ютуб канал'}>
                            <IconButton onClick={() => window.open(navbar.socialsocialNetworks.youtube, '_blank')} aria-label="YouTube">
                                <IoLogoYoutube color="blue" size='1.0em' />
                            </IconButton>
                            </Tooltip>
                            }
                            {navbar.socialsocialNetworks && navbar.socialsocialNetworks.email &&
                                <Tooltip title={'Email'}>
                                <IconButton aria-label="email" onClick={() => copyToClipboard(navbar.socialsocialNetworks.email)}>
                                <EmailIcon style={{ color: 'blue' }} />
                                </IconButton>
                                </Tooltip>
                            }
                            </Box>

                            {/* <Link href="https://www.rotary.org" target="_blank" rel="noopener noreferrer">
                                rotary.org
                            </Link>
                            <Link href="https://rotary.org.ua" target="_blank" rel="noopener noreferrer">
                                rotary.org.ua
                            </Link> */}
                            {!navbar.withoutd2232url &&
                            <Tooltip title={'rotary.org.ua'}>
                            <IconButton 
                                aria-label="website" 
                                onClick={() => window.open('https://rotary.org.ua', '_blank')}
                                style={{ color: 'blue' }} // Стиль кнопки
                            >
                                <FaGlobe />
                            </IconButton>
                            </Tooltip>}


                            <Box display="flex" flexDirection='row' alignItems='center' gap={1} >
                                {!userProfile && navbar && navbar.autorization && 
                                <SquareIconButton  href="/rc_cherkasy/myhome" aria-label="login">
                                    <LoginIcon />
                                </SquareIconButton>
                                }

                                
                                { languages &&
                                <Button style={{minWidth : '40px', padding: '0px'}} size="small" color="inherit" onClick={handleLanguageClick}>{currentLocale === LOCALES.UKRAINIAN ? 'Eng' : 'Укр'}</Button>
                                }

                                {navbar.menu && navbar.menu.length>1 &&
                                <>
                                <SquareIconButton style={{width: '30px'}} edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                                <MenuIcon />
                                </SquareIconButton>
                                
                                <Drawer style={{width: '30px'}} anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                                    <Box className="mobile-menu-container">
                                        {navbar.menu && renderMenuItems(navbar.menu, true)}
                                    </Box>
                                </Drawer>
                                </>}
                            </Box>  
                        </Box>
                              
                    </Box>
                    </Box>
                </Box>
            }
            </Toolbar>
        </AppBar>
    );
    
    
};

export default TLandingNavbar;
