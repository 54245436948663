export const getPagesContent = (local) => {
    return data_eng;
}

const data_eng = [
    /*{
        id: 0,
        header: 'Foto collage',
        arrcontent: [
            {
                PhotoCarusel: [
                    'https://api.rotary.org.ua/uploads/clubsitecreator/template_photo/defailt_image2.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/template_photo/defailt_image2.jpg',
                    'https://api.rotary.org.ua/uploads/clubsitecreator/template_photo/defailt_image2.jpg'
                ],
            },
        ],
    },*/
    {
        id: 0,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/first_image.jpg",
        header: 'Welcome to the Rotary E-Club of Ukraine!',
        content: [
            {LandingMessage: `We are Ukraine's first fully electronic Rotary club, founded in 2015 by Rotarians from temporarily occupied Crimea and Donbas. Despite all the challenges, we conduct impressive projects nationwide, uniting Ukrainians and supporters worldwide. Join us in promoting goodwill and humanitarian efforts locally and globally. Welcome to our Rotary family!`},
        ],
    },
    {
        id: 1,
        header: 'Numbers',
        LandingStatisticsBlock: [
            {LandingStistcsItem: 'type1', number: `650`, line2: `thousand dollars`, text: `Over the past two years, we have implemented projects worth more than USD 650,000` },
            {LandingStistcsItem: 'type2', number: `21`, line2: `Club members`, text: `We are proud of each of our 21 regular and honorary members` },
            {LandingStistcsItem: 'type3', number: `100+`, line2: `Partners`, text: `Over the past 2 years, more than a hundred clubs, districts and volunteers have joined the implementation of our projects` },
        ],
    },
    {
        id: 1,
        header: 'Together to our victory and beyond',
        content: [
            {LandingMessage: `round blocs with project categories or best projects loke on rotary.org`},
            {LandingMessage: `round blocs with project categories or best projects loke on rotary.org`},
            {LandingMessage: `round blocs with project categories or best projects loke on rotary.org`},
        ],
    },
];

