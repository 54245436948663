import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Typography } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

// const categories = [
//     '1. Promoting peace',
//     '2. Fighting disease',
//     '3. Providing clean water, sanitation, and hygiene',
//     '4. Saving mothers and children',
//     '5. Supporting education',
//     '6. Growing local economies',
//     '7. Protecting the environment',
//     'Other'
//     ];

    function getItemsByBdfield(data, bdfield) {
        // Шукаємо об'єкт з відповідним bdfield
        const category = data.find(item => item.bdfield === bdfield);
        // Перевіряємо, чи такий об'єкт був знайдений
        if (category) {
            // Повертаємо елементи, якщо такий bdfield був знайдений
            return category.items;
        } else {
            // Якщо не знайдено, повертаємо порожній масив
            return null;
        }
    }
    function getTitleByBdfield(data, bdfield) {
        // Шукаємо об'єкт з відповідним bdfield
        const category = data.find(item => item.bdfield === bdfield);
        // Перевіряємо, чи такий об'єкт був знайдений
        if (category) {
            // Повертаємо елементи, якщо такий bdfield був знайдений
            return category.title;
        } else {
            // Якщо не знайдено, повертаємо порожній масив
            return null;
        }
    }

const AdminDialogCrud3 = ({ open, onClose, onSubmit, isEditMode, columns, currentItem, setCurrentItem, filters }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isEditMode ? 'Edit Item' : 'Add Item'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {isEditMode ? 'Update the item details.' : 'Enter the details of the new item.'}
                </DialogContentText>
                {columns.map((column) => {
                    //if (column.type === 'lineedit' || column.type === 'multilineedit' || column.type === 'img') {
                        switch (column.type) {
                            case 'lineedit':
                            case 'multilineedit':
                            case 'img':
                                return (
                                    <TextField
                                        key={column.field}
                                        autoFocus
                                        margin="dense"
                                        id={column.field}
                                        label={column.headerName}
                                        type={column.type === 'multilineedit' ? 'text' : column.field}
                                        fullWidth
                                        variant="standard"
                                        value={currentItem[column.field]}
                                        onChange={(e) => setCurrentItem({ ...currentItem, [column.field]: e.target.value })}
                                        multiline={column.type === 'multilineedit'}
                                        minRows={column.type === 'multilineedit' ? 3 : 1}
                                    />
                                )

                            case 'combo':
                                return (
                                    <FormControl fullWidth sx={{ marginTop: '8px' }}>
                                        <Typography variant="subtitle1" sx={{ marginBottom: '4px' }}>{getTitleByBdfield(filters, column.field)}</Typography>
                                        <Select
                                        id={currentItem[column.field]}
                                        value={currentItem[column.field]}
                                        //onChange={handleCategoryChange}
                                        onChange={(e) => setCurrentItem({ ...currentItem, [column.field]: e.target.value })}
                                        >
                                        {getItemsByBdfield(filters, column.field).map((category, index) => (
                                            <MenuItem key={index} value={category}>{category}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl >
                                )
                                
                                default:
                                    return <></> 
                        }
                    //}
                    return null;
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit}>{isEditMode ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdminDialogCrud3;
