import React from 'react';
import LandingStatisticsItem from './LandingStatisticsItem';
import { Box } from '@mui/material';

const LandingStatisticsBlock = ({ landItem }) => {

    return (
        <Box 
            style={{ 
                backgroundImage: `url('https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/home-concrete-background.jpg')`, 
                backgroundAttachment: 'fixed', 
                backgroundSize: 'cover' }}
        >
            
            {landItem && JSON.stringify(landItem)}
            {landItem && landItem.map((item, index) => (
                <LandingStatisticsItem key={index} item={item} />
            ))}
        </Box>
    );
}


export default LandingStatisticsBlock;