import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';

import {getMyCherkasyLanding} from './getMyCherkasyLanding';
import {getCherkasyProjects2} from './getCherkasyProjects2';
import {getRCCherkasyRotaract} from './getRCCherkasyRotaract';
import {getRCCherkasyPartners} from './getRCCherkasyPartners';
import {getRCCherkasyLeadership} from './getRCCherkasyLeadership';
import {getMyCherkasyBdRotarians} from './getMyCherkasyBdRotarians';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingContent from '../components/LandingContent';
import MyRcCherkasyMain from './MyRcCherkasyMain';
import DocumentsFrame from '../../../pages/myrotary/DocumentsFrame';
import EventsFrame from '../../EventsFrame';
import UserCRUD from '../rotariansBD/adminsPages/UserCRUD';
import ProjectsCRUD from '../rotariansBD/adminsPages/ProjectsCRUD';

import { Container, Typography } from '@mui/material'
import { Paper } from '@mui/material';

import {  useMediaQuery, useTheme } from '@mui/material';

const pageJson = {
    undefined: getMyCherkasyLanding,
    'events': getMyCherkasyLanding,
    'communique': getMyCherkasyLanding,
    'administaration': getMyCherkasyLanding,
    'administaration2': getMyCherkasyLanding,
    "bdrotarians": getMyCherkasyBdRotarians,
};

const MyRcCherkasyPage = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { id } = useParams();

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale, id);
        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale, id);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale, page) => {
        const pageFunc = pageJson[page];
        const d = await pageFunc(locale);
        setData( d );

        console.log("DanatePage get data list success. const="+d.length);
    }

    return (
        <MyRcCherkasyMain>
            {(() => {
                switch (id) {
                    case 'events':
                        return (
                            <Container style={{ padding: '8px' }}>
                                <Paper style={{ padding: '12px', paddingLeft: isSmallScreen ? '12px' : '96px', paddingRight: isSmallScreen ? '8px' : '24px' }}>
                                    <EventsFrame />
                                </Paper>
                            </Container>
                        );
                    case 'communique':
                        return (
                            <Container style={{ padding: '8px' }}>
                                <Paper style={{ padding: '12px', paddingLeft: isSmallScreen ? '12px' : '96px', paddingRight: isSmallScreen ? '8px' : '24px' }}>
                                    <DocumentsFrame />
                                </Paper>
                            </Container>
                        );
                    case 'administaration':
                        return (
                            <div style={{padding: '24px'}}>
                                    <UserCRUD tags='РКЧеркаси' />
                            </div>
                        );
                    case 'administaration2':
                        return (
                            <div style={{padding: '24px'}}>
                                    <ProjectsCRUD tags='РК Черкаси' />
                            </div>
                        );
                    default:
                        return data ? (
                            <LandingContent data={data} currentLocale={currentLocale} designType={designType} />
                        ) : (
                            "Loading..."
                        );
                }
            })()}
        </MyRcCherkasyMain>

    );
}

export default MyRcCherkasyPage;