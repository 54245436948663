
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { IoLogoYoutube } from 'react-icons/io5';

const LcButtonYoutube = ({url,size}) => {

    return (
        <Tooltip title={'Youtube'}>
        <IconButton onClick={() => window.open(url, '_blank')} aria-label="Youtube">
            <IoLogoYoutube color="white" fontSize={size} />
        </IconButton>
        </Tooltip>
    );
}

export default LcButtonYoutube;