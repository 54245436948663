
export const getUserPagesContent = (local) => {
    return data_eng;
}

const data_eng = [
    {
        id: 1,
        header: 'Club Officers',
        LandingBdRotarians: 'rec_ukraine',
    }
    // {
    //     id: 1,
    //     header: 'Club Officers',
    //     //LandingBdRotarians: 'РеК_Україна',
    //     arrcontent: [
    //         {
    //             id: 1,
    //             cards: [
    //                 {
    //                     rotarianId: 90,
    //                 },
    //             ]
    //         }
    //     ]
    // },
    
    // {
    //     id: 1,
    //     header: 'Club Members',
    //     //LandingBdRotarians: 'РеК_Україна',
    //     arrcontent: [
    //         {
    //             id: 1,
    //             cards: [
    //                 {
    //                     rotarianId: 92,
    //                 },
    //                 {
    //                     rotarianId: 91,
    //                 },
    //             ]
    //         }
    //     ]
    // }

];

