
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {LocalContext} from '../tools/LanguageContextProvider';


import { Typography, Grid, Card, CardContent, CardMedia, Container, Box } from '@mui/material';

import './Pages.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import PhotoCarusel from '../modules/PhotoCarusel';
import LandingHeader from '../components/LandingHeader';

import { getAll } from "../api/ts/api_magazines";
import {getMagazines} from './d2232GetFunctions/getMagazines';

const MagazinesPage = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [data, setData] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    const  designType='chicago';

    useEffect(() => {
      const fetchData = async () => {
        try {
            await loadData(currentLocale);

            const video = document.getElementById('myVideo');
            video.autoplay = true;
            video.muted = true;

        } catch (error) {
            console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    useEffect(() => {
        loadData(currentLocale);
        console.log("Change curent langufge то ="+currentLocale);
    }, [currentLocale, id])

    const loadData = async(locale) => {
        const d = await getMagazines(locale);
        setData( d );
        console.log("DanatePage get data list success. const="+d.length);
    }
    
    // Групуємо журнали за роками
    const groupedMagazines = {};
    data.forEach((magazine) => {
        if (magazine.magazinelang === 'UKR') {
        if (!groupedMagazines[magazine.magazinecat]) {
            groupedMagazines[magazine.magazinecat] = [];
        }
        groupedMagazines[magazine.magazinecat].push(magazine);
        }
    });

    const handleCardClick = (url) => {
        if (url.startsWith('http')) {
            window.open(url, '_blank');
        } else {
            navigate(url);
        }
    };

    return (
    <MainPage>
        <LandingBlock mode='light'>
        <Box className="section text-left" >
            <Container>
            <video width="100%"  controls autoplay muted loop id="myVideo">
                <source src='https://rotary.org.ua/assets/site/videos/RotaryMagazine2024_v2.mp4' type="video/mp4" />
                Ваш браузер не підтримує відтворення відео.
            </video>
            <LandingHeader><Typography variant="h4" className="page-header">ЖУРНАЛ РОТАРІЄЦЬ</Typography></LandingHeader>
            Журнал «РОТАРІЄЦЬ» - офіційне видання Ротарі Інтернешнл в Білорусі та Україні, яке разом з тридцятьма двома журналами з інших країн та регіонів входить до Всесвітнього Об’єднання Ротарійських Журналів (Rotary Global Media Network). На території Дистрикту 2232 журнал «РОТАРІЄЦЬ» видається з 2007 року та діє на підставі Ліцензійної Угоди Ротарі Інтернешнл. Щороку видається 6 номерів у мультимедійному форматі та два спеціалізованих друкованих випуски – з методичними матеріалами до Навчального Семінару для Обраних Президентів Ротарі Клубів та іміджевий річний випуск до Конференції Дистрикту. Впродовж року журнал надає читачам інформацію про Ротарі в Україні, Білорусі та у світі в цілому. Мультимедійний формат дозволяє до текстів додавати відеосюжети, забезпечувати аудіосупровід, в режимі реального часу надавати інформацію за допомогою новинних віджетів, інтерактивних карт тощо.
            <Grid container spacing={3}>
                {Object.keys(groupedMagazines).reverse().map((year) => ( // Змінено порядок обходу ключів об'єкта на reverse()
                <Grid item xs={12} key={year}>
                    <LandingHeader><Typography variant="h4" className="page-header">{year}</Typography></LandingHeader>
                    <Grid container spacing={2}>
                    {groupedMagazines[year].map((magazine) => (
                        <Grid item xs={12} sm={6} md={2} lg={2} key={magazine.magazineID}>
                        <Card sx={{ maxWidth: 200 }} onClick={() => handleCardClick(magazine.magazinelink) }>
                            <CardContent>
                            <Typography variant="h6">{magazine.magazinename}</Typography>
                            <img src={"https://rotary.org.ua/assets/site/images/magazine_links/"+magazine.magazineimg} alt={magazine.magazinename} style={{ maxWidth: '100%', height: 'auto' }} />
                            </CardContent>
                        </Card>
                        </Grid>
                    ))}
                    </Grid>
                </Grid>
                ))}
            </Grid>
            </Container>
        </Box>
        </LandingBlock>
    </MainPage>
    );
  
}

export default MagazinesPage;