
import { getEventBusinesCouncil } from '../../../pages/BusinessCouncil/getEventBusinesCouncil';
import React from 'react';
import { Card, CardContent, CardMedia, CardActionArea, CardActions, Typography, Box } from '@mui/material';
//import ProjectPhotoCarusel from './ProjectPhotoCarusel';
import PhotoCarusel from '../../../modules/PhotoCarusel';
import LcButtonPdf from './LcButtonPdf';

const LandingCardEvent = ({landItem}) => {
    const itemId = landItem.LandingCardEvent;
    const data_arr = getEventBusinesCouncil();
    const event = data_arr && itemId < data_arr.length && data_arr[itemId];

    const onlydocss = (card) => {
        if (typeof card.docs === 'string') {
            // Розділення строки за комами і видалення пробільних символів
            return card.docs.split(',').map(img => img.trim());
        }
        return null;
    };

    return (
        <>
        {/*data.map((event, index) => (

            // // <Card key={index} sx={{ display: 'flex' }}>
            // <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={index}>
            // {event && event.imgs && <ProjectPhotoCarusel images={event.imgs} sx={{ order: -1 }} />}
            // <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            //     <CardContent sx={{ flex: '1 0 auto' }}>
            //         <Typography component="div" variant="h5">
            //             {event.date1}
            //         </Typography>
            //         <Typography variant="h6" color="text.primary" gutterBottom>
            //             {event.title}
            //         </Typography>
            //         <Typography variant="body1">{event.text}</Typography>
            //     </CardContent>
            // </Box>
        // </Card>*/}
                <Card sx={{  minWidth: 200, display: 'flex', flexDirection: 'column' }} //key={index} 
                    //</>onClick={() => handleRowClick(index)}
                    >
                {/* <CardActionArea> */}
                    {/* <ProjectPhotoCarusel images={event.imgs} /> */}
                    <PhotoCarusel images={event.imgs} />

                  <CardContent>
                    <Typography color='darkblue' gutterBottom variant="h5" component="div" 
                    // onClick={() => handleRowClick(index)} 
                    fontWeight="bold" >
                    {event.title}
                    </Typography>

                    <Typography gutterBottom variant="h6" component="div">
                    Дата: {event.date1}
                    </Typography>

                    {event.docs && 
                    <Typography gutterBottom variant="h6" component="div">
                        <>Документи:
                            {event.docs.map((item) => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                               <LcButtonPdf url={item.url} size='default' />
                               <Typography gutterBottom variant="h6" component="div">
                                   {item.title}
                               </Typography>
                           </div>
                        ))}
                    </>
                    </Typography>
                    }

                    {event.text && <>
                    <Typography gutterBottom variant="h6" component="div">Опис:</Typography>
                    <Typography variant="body1" color="text.secondary">
                        {event.text && 
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                            {(event.text)}
                            </Typography>
                        }
                    </Typography></>}
                    
                  </CardContent>
                {/* </CardActionArea> */}
                {/* <CardActions style={{ marginTop: 'auto' }}>
                    <Typography gutterBottom variant="h7" >
                    Primary contact: 
                    </Typography>

                    
                </CardActions> */}
                </Card>

        {/* ))} */}
        </>
    );
};

export default LandingCardEvent;
