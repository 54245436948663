import {getMenuStructure} from '../bdFunctions/getMenuStructure';
import {getPagesContent} from '../bdFunctions/getPagesContent';
import {getUserPagesContent} from '../bdFunctions/getUserPagesContent';
import {getPagesContent_projects} from '../bdFunctions/getPagesContent_projects';
//import {getProjectFiltres} from '../bdFunctions/getProjectFiltres';

import RcClubPageMain from '../../ClubPageComponents/RcClubPageMain';

const adminUserDefaultFields = [
    { field: 'tags', defValue: 'rec_ukraine' },
    { field: 'club_type', defValue: 'ReC' },
    { field: 'club_name', defValue: 'Ukraine' },
    { field: 'club_name_ukr', defValue: 'Україна' },
]
const adminProjectDefaultFields = [
    { field: 'tags', defValue: 'rec_ukraine' },
    { field: 'rtype', defValue: 'ReC' },
    { field: 'rclub', defValue: 'Ukraine' },
]

const RcEClubOfUkrainePage = () => {
    const  designType='chicago';

    return (
        <RcClubPageMain 
            menufunc={getMenuStructure} 
            pagesContent={getPagesContent}
            getUserPagesContent={getUserPagesContent}
            getPagesContent_projects={getPagesContent_projects}
            designType={designType}
            //getProjectFiltres={getProjectFiltres}
            tags='rec_ukraine'
            adminUserDefaultFields={adminUserDefaultFields}
            adminProjectDefaultFields={adminProjectDefaultFields}
        >
        </RcClubPageMain>
    );
}

export default RcEClubOfUkrainePage;