import React, { useEffect, useState, useRef } from "react";
import { Box, Container, Grid, Typography } from '@mui/material'

import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import './Pages.css';
import './DonatePage.css';

import MainPage from "../components/Main";
import LandingContentBlock from '../components/LandingContentBlock';

import {getDanateLanding} from '../api/getDanateLanding';
const breadcrumbs = "Сторінка Донатів";

const DanatePage = () => {
    const [data, setData] = useState([]);
    const menuRefs = useRef([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const d = await getDanateLanding();
          setData(d);
          console.log("DanatePage get data list success. const="+d.length);

          d.map((item, index) => {
            menuRefs.current.push(React.createRef());
            console.log("========= menuRefs="+menuRefs.current.length);
            //myRefs.current[index].current.value = "Input 1";
          })
        } catch (error) {
          console.log("DanatePage get data list error="+error);
        }
      };
      fetchData();
    }, [])

    return (
        <MainPage breadcrumbs={breadcrumbs} >

            {data && data.map((item,index) => (
                <div ref={menuRefs.current[item.id]}>
                <LandingContentBlock landItem={item} image={item.img} key={index} />
                </div>
            ))}

                  {/*<Grid container spacing={2}>
                      <Grid item md={8}>
                      <form method="POST" accept-charset="utf-8" target="_blank" action="https://www.liqpay.ua/api/3/checkout">
                      {/*<input type="hidden" name="data" value="eyJ2ZXJzaW9uIjozLCJhY3Rpb24iOiJwYXkiLCJhbW91bnQiOiI1IiwiY3VycmVuY3kiOiJVQUgiLCJkZXNjcmlwdGlvbiI6ItCc0ZbQuSDRgtC+0LLQsNGAIiwicHVibGljX2tleSI6InNhbmRib3hfaTEyMTg0NDkzNDI2IiwibGFuZ3VhZ2UiOiJ1ayJ9" />
                      <input type="hidden" name="signature" value="Id2KkoNnzSwU9e4EbQVcaG3FHfM=" />*/}{/*
                      <br/><button disabled className="pay-button" onmouseover="this.style.opacity='0.5';" onmouseout="this.style.opacity='1';">
                        <img src="https://static.liqpay.ua/buttons/logo-white.svg" name="btn_text"
                          className="pay-button-img"/>
                        <span className="pay-button-span">Сплатити з LiqPay</span>
                      </button>
                    </form>
                    <br/>
                    
                    Select an item if you want to donate:

                    Топ10 головних донорів для України...
                      </Grid>
                    </Grid>*/}
                  
        </MainPage>
  );
}

export default DanatePage;