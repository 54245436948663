export const getDanateLanding = () => {
    return defaultData;
};

const defaultData = [
    {
        id: 1,
        header: 'Сторінка Донатів для українського Дистрикту 2232',
        img: 'https://dyvensvit.org/wp-content/uploads/2019/08/Money-growth.jpg',
        content: [
            {LandingMessage: 'ROTARIANS, all over the world!'},
            {LandingMessage: 'UKRAINE needs your help!'},
        ],
    },
    {
        id: 2,
        header: 'Зробити внесок в платіжній системі',
        content: [
            {LandingButtonLink: 'Сплатити з LiqPay'},
        ],
    },
    {
        id: 3,
        header: 'Зробити внесок за реквізитами',
        content: [
            {LandingAccordionItem: 'UAH', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: [
                            {LandingMessage: 'Beneficiary Bank: АТ "Райффайзен Банк" '},
                            {LandingMessage: 'Beneficiary: PA RI DISTRICT 2232 '},
                            {LandingMessage: 'EDRPOU code: 38871769 '},
                            {LandingMessage: 'Account number/IBAN: '},
                            {LandingMessage: ''},
                            {LandingMessage: 'UA36 3808 0500 0000 0026 0018 4384 4 '},
                            {LandingMessage: 'Account currency: UAH '},
                        ]  
                    }, 
                ]
            },
            {LandingAccordionItem: 'USD', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: [
                            {LandingMessage: 'USD ACCOUNT:" '},
                            {LandingMessage: 'IBAN: UA633808050000000026009627608'},
                            {LandingMessage: ''},
                            {LandingMessage: 'SWIFT code: AVALUAUKXXX'},
                            {LandingMessage: 'Correspondent Account: 890-0260-688'},
                            {LandingMessage: 'CORRESPONDENT BANK:'},
                            {LandingMessage: 'The Bank of New York Mellon, New York, USA'},
                            {LandingMessage: 'SWIFT code: IRVTUS3N'},
                        ]
                    }                    
                ]
            },
            {LandingAccordionItem: 'EURO', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: [
                            {LandingMessage: 'EURO ACCOUNT:'},
                            {LandingMessage: 'IBAN: UA173808050000000026002771409'},
                            {LandingMessage: ''},
                            {LandingMessage: 'SWIFT code: AVALUAUKXXX'},
                            {LandingMessage: 'Correspondent Account: 55.022.305'},
                            {LandingMessage: 'CORRESPONDENT BANK:'},
                            {LandingMessage: 'Raiffeisen Bank International AG'},
                            {LandingMessage: 'SWIFT code: RZBAAT WW'},
                        ]
                    }
                ]
            },
            {LandingAccordionItem: 'GBP', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: [
                            {LandingMessage: 'ACCOUNT STERLING (GBP) '},
                            {LandingMessage: 'Account Number IBAN: UA05 3808 0500 0000 0026 0057 7542 4 '},
                            {LandingMessage: 'NAME OF ORGANIZATION: PA RI DISTRICT 2232'},
                            {LandingMessage: 'LEGAL ADDRESS: 04050, UKRAINE, Kyiv city, Gercena str, 17-25, 1'},
                            {LandingMessage: 'BANK OF BENEFICIARY: '},
                            {LandingMessage: 'Raiffeisen Bank Joint Stock Company, Leskova street.9, KYIV, 01011, Ukraine '},
                            {LandingMessage: 'SWIFT code: AVALUAUKXXX Correspondent Account: 83301931 CORRESPONDENT BANK'},
                            {LandingMessage: 'Barclays Bank PLC '},
                            {LandingMessage: 'SWIFT code: BARCGB22   '},
                        ]
                    }
                ]
            },
            {LandingAccordionItem: 'CHF', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: [
                            {LandingMessage: 'ACCOUNT SWISS FRANCS (CHF) '},
                            {LandingMessage: 'Account Number IBAN: UA79 3808 0500 0000 0026 0067 7542 3 '},
                            {LandingMessage: 'NAME OF ORGANIZATION: PA RI DISTRICT 2232 '},
                            {LandingMessage: 'LEGAL ADDRESS: 04050, UKRAINE, Kyiv city, Gercena str, 17-25, 1 '},
                            {LandingMessage: 'BANK OF BENEFICIARY: '},
                            {LandingMessage: 'Raiffeisen Bank Joint Stock Company, Leskova street.9, KYIV, 01011, Ukraine '},
                            {LandingMessage: 'SWIFT code: AVALUAUKXXX '},
                            {LandingMessage: 'Correspondent Account: 0230-69171.05M '},
                            {LandingMessage: 'CORRESPONDENT BANK '},
                            {LandingMessage: 'UBS Switzerland AG, Zurich '},
                            {LandingMessage: 'SWIFT code: UBSWCHZH80A'},
                        ]
                    }
                ]
            },
            {LandingAccordionItem: 'SEK', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: [
                            {LandingMessage: 'BENEFICIARY'},
                            {LandingMessage: 'Account number/IBAN UA26 3808 0500 0000 0026 0018 3451 8 '},
                            {LandingMessage: 'Account currency SEK '},
                            {LandingMessage: 'Beneficiary PA RI DISTRICT 2232'},
                            {LandingMessage: 'Legal address of the organization 04050, UKRAINE, Kyiv city, Gercena str, 17-25,'},
                            {LandingMessage: 'apartment/office 1'},
                            {LandingMessage: ''},
                            {LandingMessage: 'BENEFICIARY BANK'},
                            {LandingMessage: 'RAIFFEISEN BANK JOINT STOCK COMPANY,'},
                            {LandingMessage: 'KIEV, UKRAINE '},
                            {LandingMessage: 'SWIFTcode:'},
                            {LandingMessage: 'AVALUAUKXXX '},
                            {LandingMessage: 'Correspondent Account:'},
                            {LandingMessage: '81-55.022.305  '},
                            {LandingMessage: ''},
                            {LandingMessage: 'CORRESPONDENT BANK '},
                            {LandingMessage: 'Use the settlement instructions below:'},
                            {LandingMessage: 'RAIFFEISEN BANK INTERNATIONAL AG,'},
                            {LandingMessage: 'VIENNA, AUSTRIA '},
                            {LandingMessage: 'SWIFTcode: RZBAATWWXXX  '},
                        ]
                    }
                ]
            },
            {LandingAccordionItem: 'CAD', 
                itemArr: [
                    {LandingMatrixPrinterPaper: '.',
                        itemArr: [
                            {LandingMessage: 'BENEFICIARY '},
                            {LandingMessage: 'Account number/IBAN UA78 3808 0500 0000 0026 0057 8526 5 '},
                            {LandingMessage: 'Account currency CAD '},
                            {LandingMessage: 'Beneficiary PA RI DISTRICT 2232 '},
                            {LandingMessage: 'Legal address of the organization 04050, UKRAINE, Kyiv city, Gercena str, 17-25,'},
                            {LandingMessage: 'apartment/office 1  '},
                            {LandingMessage: ''},
                            {LandingMessage: 'BENEFICIARY BANK'},
                            {LandingMessage: 'RAIFFEISEN BANK JOINT STOCK COMPANY,'},
                            {LandingMessage: 'KIEV, UKRAINE '},
                            {LandingMessage: 'SWIFTcode:'},
                            {LandingMessage: 'AVALUAUKXXX '},
                            {LandingMessage: 'Correspondent Account:'},
                            {LandingMessage: '71-55.022.305   '},
                            {LandingMessage: ''},
                            {LandingMessage: 'CORRESPONDENT BANK '},
                            {LandingMessage: 'Use the settlement instructions below:'},
                            {LandingMessage: 'RAIFFEISEN BANK INTERNATIONAL AG,'},
                            {LandingMessage: 'VIENNA, AUSTRIA '},
                            {LandingMessage: 'SWIFTcode: RZBAATWWXXX '},
                        ]
                    }
                ]
            },
        ]
    },
    {
        id: 4,
        header: 'Якщо в вас є питання, можете задати його керівництву Дистрикта',
        content: [
            {LandingButtonLink: 'Задати питання'},
        ],
    },
]