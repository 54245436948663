
import { LOCALES } from '../../../i18n/locales';

export const getRCCherkasyPartners = (local) => {
    //const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData_ukr;
};
            
const cherkasyData_ukr = [
    
    {
        id: 0,
        img: "",
        header: 'Співпрацюючі організації:',

        arrcontent: [
        {
            cards: [
                {
                    id: 1,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267566863-447073930.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.uaccp.com.ua/"},
                    ],
                    name: `«УКРАЇНСЬКА АСОЦІАЦІЯ КОЛЬПОСКОПІЇ ТА ЦЕРВІКАЛЬНОЇ ПАТОЛОГІЇ»`,
                },
                {
                    id: 2,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267602371-475716354.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/Cherkasyoncocentr"},
                    ],
                    name: `“Черкаський клінічний онкологічний центр “`,
                },
                {
                    id: 3,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267624189-364509065.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://andromedaplus.ck.ua"},
                    ],
                    name: `Медичний центр «Андромеда+»`,
                },
                {
                    id: 4,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/organizations/ЧНУ.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/cnubh2015"},
                    ],
                    name: `ЧНУ ім. Б. Хмельницького`,
                },
                {
                    id: 5,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/organizations/Пласт.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/plastckua"},
                    ],
                    name: `Відокремлений підрозділ молодіжної організації Пласт національної скаутської організації в місті Черкаси в Черкаській області «Станиця Черкаси»`,
                },
                {
                    id: 6,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267612909-49213763.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/kapel.che"},
                    ],
                    name: `Капеланська служба Черкаської ТРО`,
                },
                {
                    id: 7,
                    img: 'https://kozatski-vitryla.com.ua/wp-content/uploads/2020/07/logo3-1.png',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://kozatski-vitryla.com.ua"},
                    ],
                    name: `ГО «Козацькі вітрила»`,
                },
                {
                    id: 8,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267634697-162672942.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/profile.php?id=100063592537414"},
                    ],
                    name: `ВІДОКРЕМЛЕНИЙ ПІДРОЗДІЛ ГРОМАДСЬКОЇ ОРГАНІЗАЦІЇ «ВІТРИЛЬНА ФЕДЕРАЦІЯ УКРАЇНИ» «ЧЕРКАСЬКА ОБЛАСНА ФЕДЕРАЦІЯ ВІТРИЛЬНОГО СПОРТУ»`,
                },
                {
                    id: 9,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/organizations/1713079275536-298529439.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/yachtclub.ck.ua"},
                    ],
                    name: `ГО «Черкаський яхт-клуб «Парус»`,
                },
                {
                    id: 10,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267634696-966710601.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/krainadobra1997"},
                    ],

                    name: `КОМУНАЛЬНИЙ ЗАКЛАД «ЧЕРКАСЬКИЙ НАВЧАЛЬНО-РЕАБІЛІТАЦІЙНИЙ центр Черкаської обласної ради»`,
                },
                {
                    id: 11,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267645855-125759582.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/helpanimalck"},
                    ],

                    name: `Черкаське міське товариство захисту тварин «Друг»`,
                },
                {
                    id: 12,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/organizations/Обласний_художній_музей.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/cherkasy.art.museum"},
                    ],

                    name: `Черкаський обласний художній музей`,
                },
                {
                    id: 13,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/organizations/ДСНС.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/DSNSCHERKASY"},
                    ],

                    name: `Головне управління ДСНС України у Черкаській області`,
                },
                {
                    id: 14,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267634696-33623227.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: "https://www.facebook.com/apbcherkasy"},
                    ],

                    name: `ЧІПБ імені Героїв Чорнобиля НУЦЗ України`,
                },
                {
                    id: 15,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/organizations/Департамент_освіти.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: ""},
                    ],

                    name: `Департамент освіти та гуманітарної політики Черкаської міської ради`,
                },
                {
                    id: 16,
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/organizations/Мерія_Черкаси.jpg',
                    content: [
                        {LandingLink: "офіційна сторінка", link: ""},
                    ],

                    name: `Мерія міста Черкаси`,
                },
            ]
        },],
    },
    {
        id: 0,
        img: "",
        header: 'Twin-clubs клуби-побратими:',
        content: [
            {LandingList: [
                {"LandingListItem": "1) 2018 - RC Miami Briskell, D6990 (Florida, USA);"},
                {"LandingListItem": "2) 2019 - RC Elko Desert, D5190 (Nevada, USA);"},
                {"LandingListItem": "3) 2019 - RC Springfield Sunrise, D6080 (Missouri, USA);"},
                {"LandingListItem": "4) 2020 - RC Marmaris, D2440 (Turkey);"},
                {"LandingListItem": "5) 2023 - RC Steinfurt, D1870 (Westphalia, Germany);"},
                {"LandingListItem": "6) 2023 - RC Atlanta Buckhead, D6990 (Georgia, USA);"},
                {"LandingListItem": "7) 2023 - RC Suceava, D2241 (Romania&Moldova)."},
            ]},
        ],
    },
];
const cherkasyData_eng = [
];