import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';

import { limitString } from '../../functions/limitedString';

const CRUDTable = ({ data, columns, onEdit, onDelete, onRowClicked }) => {
    
    function findFieldById(columns) {
        // Знайдемо об'єкт в масиві, де type === 'id'
        const item = columns.find(item => item.type === 'id');
      
        // Якщо об'єкт знайдено, повернемо значення його поля field
        return item ? item.field : undefined;
    }
    const field_id = findFieldById(columns);

    return (
        <TableContainer component={Paper}>
            {//data={data && JSON.stringify(data)
            }
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        {columns.map((column) => (
                            <TableCell key={column.field}>{column.headerName}</TableCell>
                        ))}
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((user, index) => (
                        <TableRow 
                            key={user[field_id]} 
                            onClick={onRowClicked ? () => onRowClicked(index) : null}
                            sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }} // Add hover style here
                        >
                            <TableCell>{index}</TableCell>
                            {columns.map((column) => {
                                switch (column.type) {
                                    case 'multilineedit':
                                        return (
                                            <TableCell key={column.field}>
                                                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                                                    {limitString(user[column.field])}
                                                </Typography>
                                            </TableCell>
                                        );
                                    case 'img':
                                        return <TableCell key={column.field}><img src={user[column.field]} height='70px' /></TableCell>;
                                    default:
                                        return <TableCell key={column.field}>{user[column.field]}</TableCell>;
                                }
                            })}
                            <TableCell>
                                <Button onClick={(e) => { e.stopPropagation(); onEdit(user); }}>Edit</Button>
                                <Button onClick={(e) => { e.stopPropagation(); onDelete(user[field_id]); }}>Delete</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CRUDTable;
