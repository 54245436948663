import React, {useState, useEffect} from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import {getRataryClubs} from '../api/rotary';
import './Pages.css';

import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import TableLineComponent from '../components/TableLineComponent';

import { fetchRClubs, createRClub, updateRClub, deleteRClub } from './RC_Cherkasy/rotariansBD/api/api_rclubs';

const breadcrumbs = ["Дистрикт 2232", "Ротарі Клуби"];

function excludeSatelliteClubs(clubs) {
    return clubs.filter(club => 
        !club.clubname.includes("Сателітний") && 
        !club.clubname.includes("Satellite")
    );
}

function filterRClubsByLang(rclubs_, checkedStateLang) {
    return rclubs_.filter(club => checkedStateLang[club.clublang]);
}

function filterRClubsByCategory(rclubs_, checkedState) {
    return rclubs_.filter(club => checkedState[club.clubcat]);
}

function sortByClubName(array) {
    return array.sort((a, b) => {
        // Перетворюємо назви клубів у нижній регістр для реєстронезалежного порівняння
        const nameA = a.clubname.toLowerCase();
        const nameB = b.clubname.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0; // Якщо назви однакові
    });
}

export const tableTitles = {
    t_clubname: "Назва клубу",
    //t_memberCount: "Кількість",
    t_charterDate: "Дата реєстрації",
};

const RotaryClubsPage = () => {
    const [rclubs, setRClubs] = useState([]);
    const [filteredRclubs, setFilteredRClubs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedRClubs = await fetchRClubs();
                
                const f4 = excludeSatelliteClubs(fetchedRClubs);
                //const filteredData = tags ? fetchedRClubs.filter(item => item.tags === tags) : fetchedRClubs;
                const filteredData = f4;

                const f3 = sortByClubName(filteredData);
                setRClubs(f3);
                
                const filteredClubs1 = filterRClubsByLang(f3, {
                    UKR: true,
                    ENG: false,
                });
                const filteredClubs2 = filterRClubsByCategory(filteredClubs1, {
                    rotary: true,
                    rotaract: false,
                    interact: false,
                  });
                setFilteredRClubs(filteredClubs2);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <LandingBlock mode='light'>
            <Container>
            <Typography variant="h6">Ротарі Клуби</Typography>
            <TableLineComponent 
                data={filteredRclubs}
                tableTitles={tableTitles}
                addNumber
            />
            </Container>
        </LandingBlock>
  );
}

export default RotaryClubsPage;