import React from 'react';
import { Box, Typography } from '@mui/material';

const LandingStatisticsItem = ({ item }) => {
    const { LandingStistcsItem, number, line2, text } = item;
    let iconColor;
    switch (LandingStistcsItem) {
        case 'type1':
            iconColor = 'blue';
            break;
        case 'type2':
            iconColor = 'green';
            break;
        case 'type3':
            iconColor = 'orange';
            break;
        default:
            iconColor = 'white';
            break;
    }
    let iconUrl;
    switch (LandingStistcsItem) {
        case 'type1':
            iconUrl = 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/home-icon-earth.png';
            break;
        case 'type2':
            iconUrl = 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/home-icon-shovel.png';
            break;
        case 'type3':
            iconUrl = 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/home-icon-vaccine.png';
            break;
        default:
            iconUrl = 'https://api.rotary.org.ua/uploads/clubsitecreator/rec_ofukraine/homepage/home-icon-earth.png';
            break;
    }

    return (
        <Box pb={3}>
            <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
                <Box textAlign="center">
                    <Typography variant="h3" component="span" color={iconColor} mr={0} sx={{ fontSize: '5em', fontWeight: 'bold' }}>
                        {number}
                    </Typography>

                    <Typography variant="body1" mb={1} color={iconColor} sx={{ fontSize: '3em' }}>
                        {line2}
                    </Typography>
                </Box>
                <img src={iconUrl} alt={LandingStistcsItem} />
            </Box>
            <Box bgcolor="white" p={3} ml={3} mr={3} mb={3} borderRadius={1}>
                <Typography variant="body2" color="black" sx={{ fontSize: '2em' }}>
                    {text}
                </Typography>
            </Box>
        </Box>
    );
}

export default LandingStatisticsItem;
