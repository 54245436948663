import React, { useState } from "react";
import { Box, Typography, Container } from '@mui/material';
import ScrollTrigger from 'react-scroll-trigger';
import './LandingContentBlock.css';

import LandingBlock from './LandingBlock';
import { FormattedMessage } from 'react-intl';
import LandingHeader from './LandingHeader';
import LandingMessage from './LandingMessage';
import LandingButtonDetails from './LandingButtonDetails';
import LandingButtonDetailsRI from './LandingButtonDetailsRI';
import LandingButtonLink from './LandingButtonLink';
import LandingList from './LandingList';
import LandingImage from './LandingImage';
import LandingLink from './LandingLink';
import LandingButtonIcon from './LandingButtonIcon';
import PhotoCarusel from '../modules/PhotoCarusel';
import VideoCarusel from '../modules/VideoCarusel';
import LandingContentItems from './LandingContentItems';
import LandingCardEvent from '../pages/RC_Cherkasy/components/LandingCardEvent';

import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import LandingCardsList from './LandingCardsList';

const LandingContentItem = ({ id, landItem}) => {
    const [isImageVisible, setImageVisible] = useState(false);
    const [isContentVisible, setContentVisible] = useState(false);
    
    const imagePosition = (id % 2 === 0 ? "left" : "right");

    const handleImageScrollProgress = (progress) => {
        if (progress.progress > 0.2) {
            setImageVisible(true);
        }
    };

    const handleContentScrollProgress = (progress) => {
        if (progress.progress > 0.1) {
            setContentVisible(true);
        }
    };

    const showCards = (cards) => {
        return (
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}  gap={2} alignItems="top" paddingTop='2em' flexWrap='wrap'>
            {cards && 
                <LandingCardsList cards={cards} />
            }
            </Box>
        )
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

    const showPhotoCaruselBlock = (imgs) => {
        return (
            <PhotoCarusel images={imgs} />
        )
    }

    const showVideoCaruselBlock = (arrLinks) => {
        return (
            <VideoCarusel videos={arrLinks} />
        )
    }

    const showContentBlock = (landItem) => {
        return (
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="flex-start">
                
                {landItem.img && imagePosition === 'left' && (
                    <Box flex={1} padding={2} paddingLeft={0}>
                        <ScrollTrigger onProgress={handleImageScrollProgress}>
                            <img src={landItem.img} width='100%' className={`slide-in-left ${isImageVisible ? 'active' : ''}`} />
                        </ScrollTrigger>
                    </Box>
                )}
                {landItem.imgs && imagePosition === 'left' && (
                    <Box flex={1} padding={2} paddingLeft={0}>
                        <ScrollTrigger onProgress={handleImageScrollProgress}>
                            {landItem.imgs.map((item) => (
                                <img src={item} width='100%' className={`slide-in-left ${isImageVisible ? 'active' : ''}`} />
                            ))}
                        </ScrollTrigger>
                    </Box>
                )}

                <Box flex={1} padding={2}>
                    <ScrollTrigger onProgress={handleContentScrollProgress}>
                        <div className={`slide-in-content ${isContentVisible ? 'active' : ''}`}>
                            <LandingContentItems content={landItem.content} />
                            {showCards(landItem.cards)}
                        </div>
                    </ScrollTrigger>
                </Box>

                {landItem.img && imagePosition === 'right' && (
                    <Box flex={1} padding={2}>
                        <ScrollTrigger onProgress={handleImageScrollProgress}>
                            <img src={landItem.img} width='100%' className={`slide-in-right ${isImageVisible ? 'active' : ''}`} />
                        </ScrollTrigger>
                    </Box>
                )}
                {landItem.imgs && imagePosition === 'right' && (
                    <Box flex={1} padding={2}>
                        <ScrollTrigger onProgress={handleImageScrollProgress}>
                            {landItem.imgs.map((item) => (
                                <img src={item} width='100%' className={`slide-in-left ${isImageVisible ? 'active' : ''}`} />
                            ))}
                        </ScrollTrigger>
                    </Box>
                )}
            </Box>
        )
    }

    return (
        <>
            {landItem.header && <Box>
                <LandingHeader>
                    <Typography variant="h4" className="page-header">
                        <FormattedMessage id={landItem.header} />
                    </Typography>
                </LandingHeader>
            </Box>}
            
            {(landItem.content || landItem.cards) && showContentBlock(landItem)}
            {(landItem.PhotoCarusel) && showPhotoCaruselBlock(landItem.PhotoCarusel)}
            {(landItem.VideoCarusel) && showVideoCaruselBlock(landItem.VideoCarusel)}

            {(landItem.LandingCardEvent) && <LandingCardEvent landItem={landItem} />}

            {(landItem.img && !landItem.content)&& 
            <img src={landItem.img} width='100%' />}
        </>
    );
    
};

export default LandingContentItem;
