
export const getFellowshipsLanding = () => {
    return defaultData;
};

const defaultData = [
    {
        id: 1,
        header: 'Ротарійські Товариства',
        content: [
            {LandingMessage: 'Зацікавлені у підводному плаванні або марафонському бігу? Бажаєте використовувати свої навички лікаря або еколога, щоб змінити світ? Поділіться своїм хобі або професією з іншими членами клубу, їхніми партнерами та друзями Ротарі.'},
        ],
    },
    {
        id: 2,
        header: 'Українські секції Ротарійських Товариств',
        img: 'https://res.cloudinary.com/tyko/image/upload/v1691578395/IYFR_website/home/iyfr3_trywmk.jpg',
        content: [
            {LandingAccordionItem: 'Міжнародне Ротарійськ Яхтове Товариство (IYFR)', 
                itemArr: [
                    {LandingMessage: 'Це новий підрозділ Товариства, що відокремився від Region East Europe за рахунок збільшення членів IYFR в Україні та Дистрикті 2232 серед членів Ротарі клубів в різних містах нашої країни.'}, 
                    {BR: "true"},
                    {LandingMessage: 'Очолює в Україні: Андрій Черепанов'},
                    {LandingLink: 'Сайт Яхтового Товариства', link: 'https://iyfr.tyko.com.ua/home'},
                    {BR: "true"},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.iyfr.net/'},
                ]
            },
            {LandingAccordionItem: 'Міжнародне Ротарійське Товариство Медичних Фахівців (IRFHP)', 
                itemArr: [
                    {LandingMessage: `"Міжнародне Ротарійське товариство медичних фахівців" (IRFHP) - це товариство-феллошип в рамках Ротарійського міжнародного об'єднання. Воно складається з медичних фахівців, які є членами Ротарійських клубів і мають інтерес до питань і проектів у сфері охорони здоров'я. Члени IRFHP спільно використовують свої знання та ресурси, щоб підтримувати гуманітарні ініціативи Ротарі в галузі охорони здоров'я. Цей феллошип надає можливість для спільної роботи, співпраці та обміну знаннями та ідеями серед медичних фахівців у Ротарійській спільноті, дозволяючи їм мати позитивний вплив на ініціативи в галузі охорони здоров'я як на локальному, так і на глобальному рівні.`}, 
                    {LandingMessage: 'Очолює в Україні: Роман Міцода'},
                    {LandingButtonDetailsRI: 'true', link: 'https://rotaryhealthprofessionals.org/'},
                ]
            },
            {LandingAccordionItem: 'Міжнародне Товариство Літаючих Ротарійців (IFFR)', 
                itemArr: [
                    {LandingMessage: `IFFR - це скорочення від "Міжнародне товариство літаючих ротаріанців". Це товариство-феллошип, яке належить до складу Ротарійського міжнародного об'єднання і об'єднує осіб, які мають пасію до авіації. Члени IFFR - це ротарійці або друзі Ротарі, які мають відношення до авіації, такі як пілоти, власники повітряних суден або прихильники авіації. Цей феллошип надає можливість авіаційним ентузіастам спілкуватися, мережитися та брати участь у товаришських заходах, а також вносити свій внесок у гуманітарні та службові проекти Ротарі. IFFR сприяє розвитку дружби та розумінню серед тих, хто об'єднаний інтересом до літання та місії Ротарі з надання служби.`}, 
                    {LandingMessage: 'Очолює в Україні: Ігор Зембович'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.iffr.org/'},
                ]
            },
            {LandingAccordionItem: 'Інші Ротарійські Товариства', 
                itemArr: [
                    {LandingMessage: `Ротарійські товариства-фелловшипи - це міжнародні групи, які об'єднують спільну пристрасть. Бути частиною фелловшипу - це цікавий спосіб заводити друзів по всьому світу, вивчати хобі або професію та покращувати ваш досвід у Ротарі.`}, 
                    {LandingMessage: 'Очолює в Україні: Ігор Зембович'},
                    {LandingButtonDetailsRI: 'true', link: 'https://www.rotary.org/en/our-programs/more-fellowships'},
                ]
            },
        ]
    },
    {
        id: 3,
        header: 'Формуються Товариства',
        img: "https://res.cloudinary.com/tyko/image/upload/v1697914201/BetaRotarySite/SitePages/4%D1%854_h757va.jpg",
        content: [
            {LandingList: [
                {"LandingListItem": "Товариство 4х4"},
                {"LandingListItem": "Товариство поціновувачів віскі"},
                {"LandingListItem": "Товариство поціновувачів вина"},
                {"LandingListItem": "Інші..."},
            ]},
        ]
    }
]
