import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, 
        Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { fetchProjects, createProject, updateProject, deleteProject } from '../api/api_projects';

import Box from '@mui/material/Box';
import CrudTable from './CrudTable';
import AdminDialogCrud3 from './AdminDialogCrud3';
import { getProjectFiltres_rec_ukraine } from '../../RC_EClub/bdFunctions/getProjectFiltres_rec_ukraine';
import { getProjectFiltres_rc_cherckasy } from '../../RcCherkasy/getProjectFiltres_rc_cherckasy';

const projectColumns = [
    { field: 'user_id', headerName: 'id', type: 'id', default: '' },
    { field: 'status', headerName: 'Status', type: 'combo', default: '' },
    { field: 'category', headerName: 'Area of focus', type: 'combo', default: '' },
    { field: 'category2', headerName: 'Category', type: 'combo', default: '' },
    { field: 'budget', headerName: 'Budget', type: 'combo', default: '' },
    { field: 'title', headerName: 'Title', type: 'lineedit', default: '' },
    { field: 'description', headerName: 'Description', type: 'multilineedit', default: '' },
    { field: 'email', headerName: 'Email', type: 'lineedit', default: '' },
    { field: 'phone', headerName: 'Phone', type: 'lineedit', default: '' },
];

const generateDefaultObject = (columns) => {
    return columns.reduce((acc, column) => {
        acc[column.field] = column.default;
        return acc;
    }, {});
};

const CRUD_ClubProjects = ({tags, adminProjectDefaultFields}) => {
    const [projects, setProjects] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentProject, setCurrentProject] = useState(generateDefaultObject(projectColumns));
    const [isEditMode, setIsEditMode] = useState(false);
    const filters1 = getProjectFiltres_rec_ukraine();//tags==='rec_ukraine' ? getProjectFiltres_rec_ukraine() : getProjectFiltres_rc_cherckasy;
    const filters2 = getProjectFiltres_rc_cherckasy();
    const filters = tags==='rec_ukraine' ? filters1 : filters2;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        const fetchData = async () => {
            try {
                const fetchedProjects = await fetchProjects();
                const filteredData = tags ? fetchedProjects.filter(item => item.tags === tags) : fetchedProjects;

                setProjects(filteredData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }
    
    const handleClose = () => {
        setOpen(false);
    };

    // const handleSubmit = async () => {
    //     console.log('=== handleSubmit...');
        
    //     const currentProjectNew = { ...currentProject };
    //     adminProjectDefaultFields.forEach(({ field, defValue }) => {
    //         currentProjectNew[field] = defValue;
    //     });
    //     console.log('adminUserDefaultFields user='+JSON.stringify(currentProjectNew))
    const handleSubmit = async () => {
        console.log('=== handleSubmit...');const currentProjectNew = { ...currentProject };

        const currentProject_new = { ...currentProject };
        adminProjectDefaultFields.forEach(({ field, defValue }) => {
            currentProject_new[field] = defValue;
        });
        console.log('adminProjectDefaultFields project='+JSON.stringify(currentProject_new))
        // let currentProject_new = currentProject;
        // //if (tags==='#rec_ukraine') { 
        //     currentProject_new.tags = '#rec_ukraine';
        //     currentProject_new.rtype = 'ReC';
        //     currentProject_new.rclub = 'Ukraine';
        //     //console.log('handleSubmit2 default tags = '+tags);
        // //}
        try {
            if (isEditMode) {
                await updateProject(currentProject_new.project_id, currentProject_new);
            } else {
                await createProject(currentProject_new);
            }
            handleClose();
            loadData();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (projectId) => {
        try {
            await deleteProject(projectId);
            loadData();
        } catch (error) {
            console.error(error);
        }
    };

    const handleEditProject = (project = generateDefaultObject(projectColumns)) => {
        console.log('handleEditProject project='+JSON.stringify(project))
        setCurrentProject(project);
        setIsEditMode(!!project.project_id);
        setOpen(true);
    };

    return (
        <div>
            <Box display="flex" flexDirection="row" pt='8px'>
                <Button variant="contained" color="primary" onClick={() => handleEditProject()}>
                    Add Project
                </Button>

                <Box width="100%">
                </Box>
            </Box>

            <CrudTable data={projects} columns={projectColumns} onEdit={handleEditProject} onDelete={handleDelete} />

            <AdminDialogCrud3
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                isEditMode={isEditMode}
                columns={projectColumns}
                currentItem={currentProject}
                setCurrentItem={setCurrentProject}
                filters={filters}
            />
        </div>
    );
}

export default CRUD_ClubProjects;
