
import { LOCALES } from '../../../i18n/locales';

export const getRCLanding = (local) => {
    const cherkasyData = local === LOCALES.UKRAINIAN ? cherkasyData_ukr : cherkasyData_eng;
    return cherkasyData;
};

const imgUrls = [
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/Засідання/37.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/Засідання/38.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/Засідання/39.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/Засідання/40.jpg",
]

const arrList1_ukr = [
    {"LandingListItem": "лінк1", 
        url: "https://drive.google.com/file/d/1itpLpIQpK99TPOYI6j9wdQNVAgMpdNYJ/view?usp=drive_link"},
    {"LandingListItem": "лінк2", 
        url: "https://docs.google.com/document/d/1nutQHjJdFyCT7V05r2LqyTOqi-d5i4IH/edit?usp=drive_link&ouid=116503399666831052031&rtpof=true&sd=true"},
    {"LandingListItem": "лінк3", 
        url: "https://drive.google.com/file/d/1Dma5lzaMG05C7WTP-d4zg3FqgYxJE_vh/view?usp=drive_link"},
    {"LandingListItem": "лінк4", 
        url: "https://drive.google.com/file/d/12Kw1_JdNVD4mwgDTt_u9eBQYe6DQCKAx/view?usp=drive_link"},
    {"LandingListItem": "лінк5", 
        url: "https://drive.google.com/file/d/1Qcagzn5sVjhMM4LM4jDMEtoOjOmcEgu4/view?usp=drive_link"},
];

const imgUrls2 = [
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705315382594-279346770.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267437362-961670161.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267437361-412567186.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267448200-194085672.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267448202-256620190.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705496918690-952925643.jpg",
];  

const imgUrls3 = [
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267437362-961670161.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267437361-412567186.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267448200-194085672.jpg",
    "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267448202-256620190.jpg",
]; 

const arrList2_ukr = [
    {"LandingListItem": "1996-1997 1) Alexander Goroh "},
    {"LandingListItem": "1997-1998 2) Alexander Goroh "},
    {"LandingListItem": "1998-1999 Alexander Sologub "},
    {"LandingListItem": "1999-2000 Ihor Savelyev "},
    {"LandingListItem": "2000-2001 Alexander Tsepko "},
    {"LandingListItem": "2001-2002 1) Viktor Vovk "},
    {"LandingListItem": "2002-2003 Artur Oganesyan "},
    {"LandingListItem": "2003-2004 Svitlana Petrova "},
    {"LandingListItem": "2004-2005 1) Kostyantyn Salenko "},
    {"LandingListItem": "2005-2006 Tetiana Bozhko "},
    {"LandingListItem": "2006-2007 Olexander Hafiyak "},
    {"LandingListItem": "2007-2008 Andranik Kazaryan "},
    {"LandingListItem": "2008-2009 Mykhaylo Safonov "},
    {"LandingListItem": "2009-2010 Невідомо, не можемо знайти інформацію "},
    {"LandingListItem": "2010-2011 2) Kostyantyn Salenko "},
    {"LandingListItem": "2011-2012 Ivan Synook "},
    {"LandingListItem": "2012-2013 Yevhen Prylypko "},
    {"LandingListItem": "2013-2014 Olga Yalovenko "},
    {"LandingListItem": "2014-2015 2) Viktor Vovk "},
    {"LandingListItem": "2015-2016 3) Konstyantyn Salenko "},
    {"LandingListItem": "2016-2017 Oleg Kuklin "},
    {"LandingListItem": "2017-2018 Nadia Gorbachenko "},
    {"LandingListItem": "2018-2019 Olha Paliychuk "},
    {"LandingListItem": "2019-2020 Nataliia Levoshyna "},
    {"LandingListItem": "2020-2021 Yuliia Chabanenko "},
    {"LandingListItem": "2021-2022 Valeriy Begmenko "},
    {"LandingListItem": "2022-2023 3) Viktor Vovk "},
    {"LandingListItem": "2023-2024 4) Viktor Vovk "},
    {"LandingListItem": "2024-2025 2) Valeriy Begmenko "},
    {"LandingListItem": "2025-2026 2) Yuliia Chabanenko "},
    {"LandingListItem": "2026-2027 ... "},
];

const cherkasyData_ukr = [
    {
        id: 0,
        header: 'Ротарі Клуб Черкаси',
        arrcontent: [
            {
                PhotoCarusel: imgUrls,
            },
        ],
    },
    {
        id: 1,
        imgs3: [
            "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705499050870-718437573.jpg",
            "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267539129-196935400.jpg",
        ],
        header: 'Місія:',
        
                content: [
                    {LandingMessage: `
                    Наша місія - зміцнювати спільноту та громади міста Черкаси і Черкаської області через соціально-важливі проєкти, підтримку освіти, охорони здоров'я та навколишнього середовища, розвиток молоді та культурних зв’язків. Ми прагнемо бути каталізатором позитивних змін, що покращують життя на Черкащині.`},
                ],
            
    },
    {
        id: 2,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267448200-609949631.jpg",
        header: 'Візія:',
        content: [
            {LandingMessage: `Наша візія полягає у тому, щоб Ротарі Клуб Черкаси відігравав роль провідної рушійної сили в громаді, яка сприяє позитивним змінам та сталому розвитку міста й області. Ми хочемо створити міцну мережу лідерів та експертів, які спільно з іноземними партнерами та друзями-ротарійцями з України та з-за кордону працюють на користь всіх мешканців Черкас та Черкащини.`},
        ],
    },
    {
        id: 3,
        header: 'Наші задачі та цілі:',
        imgs3: [
            "https://res.cloudinary.com/tyko/image/upload/v1704386104/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/homePage/0-02-04-37f4da32a604db290e8aae8ab5cc051e966798f68c2dc28fa8ecbd99ab674d76_909806170878aed3_koeegw.jpg",
            "https://res.cloudinary.com/tyko/image/upload/v1704386100/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/homePage/0-02-04-bc1e068c5e5c7629dfddb6d51f45ab1459a569a9ad3ab8085d712ed2a313c6b3_96825cf4c3c3e15c_knvfte.jpg",
            "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267612909-127439609.jpg",
            "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705497940745-974725191.jpg",
        ],
        content: [
            {LandingMessage: `В команді Ротарі Клубу Черкаси працюють віддані та натхненні лідери, які є прикладом для наслідування у громаді, що віддають свій час, знання та фінансові можливості для покращення життя місцевої громади та для реалізації соціально-важливих проєктів Ротарійського Служіння.`},
            {LandingMessage: `Правило 4-ох Ротарійських питань:`},
            {LandingList: [
                {"LandingListItem": "Чи в цьому ІСТИНА?"},
                {"LandingListItem": "Чи СПРАВЕДЛИВО це по відношенню до всіх, кого це стосується?"},
                {"LandingListItem": "Чи це зміцнить ДОБРУ ВОЛЮ та ДРУЖБУ?"},
                {"LandingListItem": "Чи буде це НА БЛАГО всім зацікавленим?"},
            ]},
            {LandingMessage: "Наші цілі включають підтримку місцевих ініціатив, сприяння розвитку освіти та охорони здоров'я, а також активне залучення молоді до волонтерської діяльності та лідерства."},
            {LandingList: [
                {"LandingListItem": "Підтримка місцевих громад: Фінансування та організація соціальних проектів.", url: ""},
                {"LandingListItem": "Освітні програми: Спонсорство стипендій, проведення семінарів та воркшопів.", url: ""},
                {"LandingListItem": "Охорона здоров'я: Організація медичних кампаній, забезпечення обладнання для лікарень.", url: ""},
                {"LandingListItem": "Робота з молоддю: Підтримка молодіжних ініціатив, створення можливостей для лідерства.", url: ""},
                {"LandingListItem": "Культурні заходи: Проведення фестивалів, виставок та культурних заходів.", url: ""},
            ]},
        ]
    },
    {
        id: 5,
        header: 'Історія:',
        imgs3: [
            "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267505840-199167958.jpg",
            "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267513810-412412897.jpg",
        ],
        content: [
            {LandingMessage: "Президенти Ротарі клубу Черкаси:"},
            {LandingList: arrList2_ukr},
        ]
    },
    {
        id: 6,
        header: 'Символіка:',
        arrcontent: [
            {
                id: 1,
                cards: [
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705498334705-259905960.jpg',
                        content: [
                            {LandingMessage: "Лого Клубу сучасне"},
                        ],
                    },
                    {
                        img: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267624189-544615742.jpg",
                        content: [
                            {LandingMessage: "Знак встановлений в м.Черкаси в 2017 до 20-річчя Ротарійського руху на Черкащині"},
                        ],
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267505839-599416865.jpg',
                        content: [
                            {LandingMessage: "Лого Клубу до 2020/21 включно"},
                        ],
                    },
                ]
            }
        ]
    },
    {
        id: 8,
        img: "https://api.rotary.org.ua/uploads/clubsitecreator/rc_cherkasy/photo/1705267448201-367136829.jpg",
        header: 'Дирекція клубу:',
        content: [
            {LandingMessage: "Дирекція Клубу 2023/24 (зараз):"},
            {LandingList: [
                {"LandingListItem": "Club President: Віктор Вовк"},
                {"LandingListItem": "Club Vice-President: Андранік Казарян"},
                {"LandingListItem": "Club Secretary: Микола Безгуба"},
                {"LandingListItem": "Club Treasurer: Валерій Бегменко"},
                {"LandingListItem": "Club Foundation Chair: Ольга Палійчук"},
                {"LandingListItem": "Club Executive Director: Юлія Чабаненко"},
                {"LandingListItem": "Club Membership Chair: Світлана Білоус"},
                {"LandingListItem": "Club Public Image Chair: Надія Горбаченко"},
                {"LandingListItem": "Club Service Projects Chair: Олексій Трифонов"},
                {"LandingListItem": "Club Learning Facilitator: Ірина Кобец"},
            ]},
            {LandingMessage: "Дирекція Клубу 2024/25 (наступний рік):"},
            {LandingList: [
                {"LandingListItem": "Club President: Валерій Бегменко"},
                {"LandingListItem": "Club Vice-President: Андранік Казарян"},
                {"LandingListItem": "Club Secretary: Віталій Юхимчук"},
                {"LandingListItem": "Club Treasurer: Віктор Вовк"},
                {"LandingListItem": "Club Foundation Chair: Ольга Палійчук"},
                {"LandingListItem": "Club Executive Director: Юлія Чабаненко"},
                {"LandingListItem": "Club Membership Chair: Надія Горбаченко"},
                {"LandingListItem": "Club Public Image Chair: Юлія Гурнак"},
                {"LandingListItem": "Club Service Projects Chair: Олексій Трифонов"},
                {"LandingListItem": "Club Learning Facilitator: Ірина Кобец"},
            ]},
        ]
    },
    {
        id: 9,
        header: 'Про членів Клуба під час війни в Україні:',
        content: [
            {LandingMessage: `Серед 30 членів Клубу - 11 (36,7%) жінок та 19 чоловіків.
            Серед них 4 Почесних членів Клубу (1 іноземець).
            7 членів мають Paul Harris Award, 1 - Rotary Service Award, 1 - Major Donor 1 level, 2 члени Клубу є діючими Головами Комітетів Дистрикту 2232 (Україна).
            20 членів постійно проживають в Україні, а 10 (33,3%) знаходяться за кордоном (з них 3 ВПО з родинами під час війни): 
            Канада – 2 (бізнес), 
            Німеччина – 1 (ВПО), 
            Австралія – 1 (бізнес), 
            Франція – 1 (ВПО), 
            Швейцарія – 1 (сімейні обставини), 
            Нідерланди – 1 (ВПО), 
            Бангладеш – 2 (бізнес), Великобританія - 1 (Почесний Член РК ротарієць з Шотландії).
            Усі приймають участь в засіданнях офлайн під час візитів в Україну, в інший час – онлайн участь.
            Співпраця в програмах подолання гуманітарної катастрофи проводиться з наступними Ротарійськими Дистриктами:
            Д2231 (Польща), 
            Д2241 (Румунія-Молдова), 
            Д1911 (Угорщина), 
            Д1913 (Хорватія), 
            Д2240 (Словакія), 
            Д2440 (Туреччина), 
            Д9560 (Австралія), 
            Д6990, Д6450, Д6080, Д7910, Д5190, Д5890 (США), 
            Д1870 (Німеччина), 
            Д1970 (Португалія), 
            Д2130 (Бельгія).
            Спільна робота поводиться з багатьма Ротарі, Ротаракт та Інтеракт клубами в Україні та за кордоном (див. перелік Дистриктів вище), з Lions International Clubs.
            А також з Ротарійськми Товариствами і Групами Дії та міжнародними і вітчизняними організаціями: Invictus Games Ukraine Тeam, USAID, IYFR, IFFR, IRFHP, ВООЗ, UNICEF
            В усіх 7 напрямках діяльності Ротарі:
            ✓ Миробудування та запобігання конфліктам (напрямок допомоги ДСНС, рятувальникам та вогнеборцям, підтримка гуманітарного розмінування)
            ✓ Профілактика та лікування хвороб 
            ✓ Вода, санітарія та гігієна 
            ✓ Здоров’я матері та дитини 
            ✓ Початкова освіта і грамотність 
            ✓ Економічний розвиток громад (соціально-орієнтовані бізнес, проекти для ВПО).
            ✓ Збереження навколишнього середовища ви маєте програми (IYFR World Plastic Free Waters Program).
            Соціально-важливі програми Ротарі клубу Черкаси:
            - Rotary Free Book Kids, 
            - Children Love Sail Ukraine – Help Young Sailors, 
            - Rotary Hospital Beds, 
            - Agro Mine Free Ukraine, 
            - Помічники Св. Миколая, 
            - Пласт Черкаси та підтримка патріотичного виховання молоді, 
            програма допомоги жінкам ВПО, 
            - програма співпраці з психологами та психотерапевтами по розробці щоденників для терапевтичних занять, 
            - виробництво реабілітаційних тренажерів, 
            - дитячі реабілітаційні спортивні табори (вітрильні, футбольні, змагання з арм-реслінгу), 
            - програма обладнання шкільних класів безпеки та бомбосховищ, 
            - програма підтримки дітей з родин військових ЗСУ та ТРО. які загинули чи пропали без звістки спільно з Капеланською службою, 
            - програма підтримки дітей з інвалідністю (онкохворі, аутичний спектр, з вадами слуху, с-м Дауна), 
            - підтримка слабозорих та інших людей з інвалідністю, у т.ч. маломобільних та в інвалідних візочках, 
            - популяризація профілактичних щеплень та 
            - підтрика донорства препаратів крові.
            Основними завданнями Клубу у воєнні часи є вирішення найбільш актуальних проблем Черкащини:
            - допомога родинам ВПО з дітьми, 
            - підтримка медичних закладів області, опорних та військових лікарень, де надають медичну допомогу постраждалим під час війни.`},
        ]
    },
    {
        id: 11,
        header: 'Приєднуйтесь до нас:',
        img: "https://res.cloudinary.com/tyko/image/upload/v1704548318/BetaRotarySite/%D0%A1%D0%B0%D0%B9%D1%82%D0%9A%D0%BB%D1%83%D0%B1%D1%96%D0%B2/RC_Cherkasy/share/0-02-08-b52959a815a2155def81a56753510516c2c4df8f881a9add7139d2b76c0efb4e_2da8cf69390eccdb_ewlei2.jpg",
        content: [
            {LandingMessage: "Ми завжди раді вітати нових членів, які хочуть долучитися до нашої місії та внести свій вклад у розвиток громади Черкас."},
            {LandingButtonLink: "Стати членом клубу", link: "https://forms.gle/ACjBtZZaGiWaZZBt8"},
        ]
    },
];

const cherkasyData_eng = [
    {
        id: 0,
        header: 'Ротарі Клуб Черкаси',
        arrcontent: [
            {
                PhotoCarusel: imgUrls,
            },
            {
                content: [
                    {LandingMessage: `The club's annual project of a friendly photo session on the eve of Christmas and New Year holidays`},
                ],
            },
        ],
    },
    {
        id: 1,
        content: [
            {LandingMessage: `(translated...)`},
        ],
    },
];