import { useEffect, useState, useContext, useRef } from "react";
import { useParams } from 'react-router-dom';

import TykoLanding from '../components/TykoLanding';
import {LocalContext} from '../../../tools/LanguageContextProvider';
import LandingContent from '../components/LandingContent';

import CRUD_ClubUser from '../rotariansBD/adminsPages/CRUD_ClubUser';
import CRUD_ClubProjects from '..//rotariansBD/adminsPages/CRUD_ClubProjects';

const RcClubPageMain = ({
        designType, 
        menufunc,
        pagesContent, 
        getUserPagesContent, 
        getPagesContent_projects, 
        tags, 
        adminUserDefaultFields,
        adminProjectDefaultFields
    }) => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [navbar, setNavbar] = useState(null);
    const [footer, setFooter] = useState(null);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
          try {
              await loadData(currentLocale);
          } catch (error) {
              console.log("DanatePage get data list error="+error);
          }
        };
        fetchData();
    }, [])

    useEffect(() => {
        console.log("Change curent langufge то ="+currentLocale);

        loadData(currentLocale);
    }, [currentLocale])

    const loadData = async(locale) => {
        const page = menufunc(locale);
        setNavbar(page.navbar);
        setFooter(page.footer);

        const d = await pagesContent(locale);
        setData( d );
        const d2 = await getPagesContent_projects(locale);
        setData2( d2 );
        const d3 = await getUserPagesContent(locale);
        setData3( d3 );

        console.log("DanatePage get data list success. const="+page.length);
    }

    return (
        <>
        
            <TykoLanding 
                navbar={navbar} 
                designType={designType} 
                // mynavbar={mynavbar} 
                // noWarningMode="hide" 
                // designType={designType} 
                // footer={footer} 
                // languages={["Укр"]} 
                // userProfile={userProfile} 
                // onLogined={onLogined} 
                // isClubMember={isClubMember} 
            >
            {(() => {
                switch (id) {
                    case 'overview':
                        return (
                            <LandingContent data={data} currentLocale={currentLocale} designType={designType} />
                        );
                    case 'projects':
                        return (
                            <LandingContent data={data2} currentLocale={currentLocale} designType={designType} />
                        );
                    case 'members':
                        return (
                            <LandingContent data={data3} currentLocale={currentLocale} designType={designType} />
                        );

                    case 'adminmember':
                        return (
                            <CRUD_ClubUser tags={tags} adminUserDefaultFields={adminUserDefaultFields} />
                        );
                    case 'adminprojects':
                        return (
                            <CRUD_ClubProjects tags={tags} adminProjectDefaultFields={adminProjectDefaultFields} />
                        );    

                    default:
                        return data ? (
                            <LandingContent data={null} currentLocale={currentLocale} designType={designType} />
                        ) : (
                            "Loading..."
                        );
                }
            })()}
            </TykoLanding>
        </>
    )
};

export default RcClubPageMain;